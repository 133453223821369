import {useGlobalEmitter} from "@/helper/emitter";
import {TextToSpeech} from "@/helper/chat/textToSpeech";
import {ref} from "vue";
import {consoleLogChat} from "@/helper/console";
import {audioContextPlay, stopAudio} from "@/helper/audioContext";
import {useChatStore} from "@/helper/chat/chatStore";


// * Voice Player * //

const isPlaying = ref(false);

let processing = false
export const processTTSObject = async (ttsObject: TextToSpeech) => {
    if (processing) {
        throw new Error('shouldn\'t happen')
    }

    processing = true

    const chatStore = useChatStore()

    chatStore.setTtsStatus("tts");

    const stream = await ttsObject.ttsPromise

    consoleLogChat('Speaking: %o', ttsObject.content)
    isPlaying.value = true;
    chatStore.setTtsStatus("speaking");
    await audioContextPlay(stream, () => {
        consoleLogChat('Speaking finished')
        isPlaying.value = false;
        processing = false
        chatStore.setTtsStatus("silent");
        checkForQueuedTTSObjects();
    })

};

const checkForQueuedTTSObjects = async () => {
    const chatStore = useChatStore()

    if (processing) {
        // console.log("already processing for playing.");
        return
    }

    const ttsObject = chatStore.pullTtsObject()
    if (!ttsObject) {
        // console.log("No new TTS to play");
        return
    }

    if (!chatStore.isVoiceMode) {
        // drop
        return
    }
    processTTSObject(ttsObject);
};

/** init **/

let initialized = false

const init = () => {
    const chatStore = useChatStore()

    const onNewTextMessage = (value: string) => {
        if (!chatStore.isVoiceMode) {
            return
        }

        // console.log("Create new TTS object for '" + value + "'");
        const ttsObject = new TextToSpeech(value);
        chatStore.pushTtsObjects(ttsObject);
        checkForQueuedTTSObjects();
    }

    const onVoiceModeToggle = (enabled:boolean) => {
        if (!enabled) {
            // clear queue on voice mode exit
            stopAudio()
            chatStore.resetTtsObjects()
            useGlobalEmitter().off('VoiceModeNewTextMessage', onNewTextMessage);
            useGlobalEmitter().off('VoiceModeToggle', onVoiceModeToggle);

            initialized = false
        }
    }

    useGlobalEmitter().on('VoiceModeNewTextMessage', onNewTextMessage)
    useGlobalEmitter().on('VoiceModeToggle', onVoiceModeToggle)
}

export const useSpeakingPlayer = () => {
    if (initialized) {
        return
    }

    init()
    initialized = true
}

import {tts} from "@/helper/chat/assistantAPI";
import {ChatCompletionStream} from "openai/lib/ChatCompletionStream";


export class TextToSpeech {

    public ttsPromise : Promise<ChatCompletionStream>

    constructor(
        public content: string
    ) {
        this.content = content

        this.ttsPromise = new Promise((resolve) => {
            tts(this.content)
                .then(async (response: ChatCompletionStream) => {
                    resolve(response)
                    // for await (const chunk of response) {
                    //     if(chunk?.choices[0]?.delta?.audio?.data){
                    //         this.currentAudio.push(chunk.choices[0].delta.audio.data)
                    //     }
                    // }
                })

        })



    }
}

<template>
    <ion-page class="no-padding-top">
        <ion-loading
            :is-open="RS_loader.global"
            :message="RS_loader.globalMessage"
        />
        <ion-header :translucent="true">
            <ion-toolbar class="no-border">
<!--                <ion-button-->
<!--                    color="secondary"-->
<!--                    fill="clear"-->
<!--                    v-on:click="router.go(-1)"-->
<!--                    size="small"-->
<!--                >-->
<!--                    <ion-icon slot="icon-only" src="/icons/caret-left.svg" />-->
<!--                </ion-button>-->
<!--                <ion-title>Assistent</ion-title>-->
                <ion-buttons slot="start">
                    <ion-button
                        v-if="!chatStore.nothingHappenedYet"
                        color="secondary"
                        fill="clear"
                        @click="resetConversation()"
                    >
                        <ion-icon slot="icon-only" :src="refreshOutline" />
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button
                        color="secondary"
                        fill="clear"
                        v-on:click="aInesRequestStore.toggleUIState"
                        class="image-icon has-badge"
                    >
                        <img slot="icon-only" src="/logo.png" />
                        <ion-badge v-if="RS_show.ainesRequestBadge && aInesRequestStore.unhandledRequestsLength">{{ aInesRequestStore.unhandledRequestsLength }}</ion-badge>
                    </ion-button>
                    <ion-button
                        v-if="RS_show.calendarButton"
                        color="secondary"
                        fill="clear"
                        v-on:click="calendarModalOpen = true"
                    >
                        <ion-icon slot="icon-only" src="/icons/calendar-blank.svg" />
                    </ion-button>
                    <ion-button
                        v-if="authStore.isConsultant()"
                        color="secondary"
                        fill="clear"
                        v-on:click="router.push('/employees')"
                        class="impersonation"
                    >
                        <ion-icon slot="icon-only" src="/icons/detective.svg" />
                        <ion-label>{{ employeeName }}</ion-label>
                    </ion-button>
                </ion-buttons>

            </ion-toolbar>
        </ion-header>
        <ion-content>

            <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="150">
                <AInesRequestAssistant
                    v-if="RS_show.ainesRequestSummary"
                    v-on:avatar:click="onAInesRequestAvatarClick"
                    v-on:request:click="onAInesRequestSelect"
                />
            </Transitionator>
            <div class="chat-container">
                <div class="response-container">

                    <!-- VOICE OR TEXT MODE -->
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce">
                        <AInesVoiceAvatar
                            v-if="RS_show.voiceMode"
                        />
                    </Transitionator>
                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="150">
                        <AInesMessage
                            v-if = "RS_show.textMode && (RS_show.messageText || RS_show.messageLoaderMessage)"
                            :text-content="RS_assistantMessage?.textContent || ''"
                            />
                    </Transitionator>

                    <Transitionator :types="['opacity', 'margintop']" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="10">
                        <AInesMessage
                            v-if = "RS_show.messageLoaderDots || RS_show.richResponseLoaderDots"
                            :text-content="''"
                            :show-pending="true"
                        />
                    </Transitionator>
                    <div
                        v-if="RS_show.messageRichResponses && RS_assistantMessage?.richContents"
                        v-for="richContent in RS_assistantMessage.richContents"
                        class='rich-response-container'
                    >
                        <Transitionator v-if="!!getRichComponentFor(richContent)" :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="20">
                            <component
                                :is="getRichComponentFor(richContent)"
                                :init-data="richContent.initData"
                                v-on:details="onRichResponseDetails"
                            />
<!--                                @blockprompt="onRichComponentBlockPrompt"-->
<!--                                @unblockprompt="onRichComponentUnblockPrompt"-->
<!--                                @sendmessage="onRichComponentSendMessage"-->

                        </Transitionator>
                    </div>
                </div>
            </div>
            <CalendarDetail v-if="dayDetailModalOpen" v-model="clickedDate" :date-id="clickedDate"></CalendarDetail>
            <CalendarModal
                v-model="calendarModalOpen"
                :initial-date="today"
            />
        </ion-content>

        <ion-footer class="footer-input">

            <!-- chat input -->
            <div
                class="chat-input-container">
                <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="50">
                    <ChatInput
                        ref="chatInputRef"
                        v-if="chatStore.isTextMode"
                        v-on:text:submit="(message) => chatSendsNewUserMessage(message, {checkForLoginLink: true})"
                        v-on:text:input="onChatInputTextInput"
                        v-on:text:paste="onChatInputTextPaste"
                        :disabled="RS_loader.setup"
                    />
                </Transitionator>
                <Transitionator :types="['opacity', 'scale']" :module-options="{scale: {base: 0.8}}" easing-enter="easeOutBounce" easing-leave="easeInBounce" :duration-leave="50">
                    <VoiceInput
                        v-if="chatStore.isVoiceMode"
                        v-on:text:submit="(message) => chatSendsNewUserMessage(message)"
                        :disabled="RS_loader.setup"
                    />
                </Transitionator>
                <ion-button
                    v-on:click="onToggleVoiceTextMode"
                    color="white"
                    class="floating-big-pseudo"
                    :disabled="RS_loader.setup"
                >
                    <ion-icon
                        slot="icon-only"
                        color="primary"
                        :src="chatStore.isVoiceMode ? '/icons/x.svg' : '/icons/microphone.svg'"
                    />
                </ion-button>
            </div>

            <!-- quick chat -->
            <div
                class="quick-access-buttons"
                :class="{'centered': chatStore.isVoiceMode}"
                v-if="RS_show.quickChats">
                <div v-for="qc in RS_quickChats" :key="qc.label" class="quick-access-item">
                    <div class="floating-big-pseudo" @click="qc.click">
                        <IonIcon :icon="qc.icon || ''"></IonIcon>
                        {{ qc.label }}
                    </div>
                </div>
            </div>
        </ion-footer>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonLabel,
    IonLoading,
    IonPage,
    IonToolbar
} from "@ionic/vue";
import ChatInput from '@/views/Chat/ChatInput.vue'
import VoiceInput from '@/views/Chat/VoiceInput.vue'
import {computed, ComputedRef, Ref, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {
    ExploreQuickChat,
    getExploreQuickChatsMatching,
    getExploreQuickChatsTopN,
    useExploreQuickChatStore
} from "@/helper/chat/exploreQuickChats";

import {PreprocessedAssistantMessage, useChatStore} from "@/helper/chat/chatStore";
import {resetConversation, sendUserMessage, setUpAssistant, setUpAssistantDone} from "@/helper/chat/chatBL";
import AInesRequestAssistant from "@/views/Chat/AInesRequestAssistant.vue";
import {
    AInesRequestAssistantUIState,
    getRequestResponseQuickChats,
    ResponseQuickChat,
    snoozeQuickChatHandler,
    useAInesRequestStore
} from "@/helper/chat/ainesRequests";
import {AInesAssistantType, AInesRequest, AInesRichResponseType} from "@/graphql/generated/graphql";
import {getRichComponentFor, RichContent} from "@/helper/chat/richResponses";
import Transitionator from "../components/transitions/Transitionator.vue";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {requireAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {consoleErrorChat} from "@/helper/console";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useAuthStore} from "@/store/auth";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {useGlobalEmitter} from "@/helper/emitter";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";

import AInesVoiceAvatar from "@/views/Chat/AInesVoiceAvatar.vue";
import date from "@/helper/datetime/date";
import {refreshOutline} from "ionicons/icons";
import {useEmployeeStore} from "@/store/employee";
import {useCandidateStore} from "@/store/candidate";

import MarkdownIt from "markdown-it"
import AInesMessage from "@/views/Chat/AInesMessage.vue";
import {ClickableQuickChat} from "@/helper/chat/quickChat";
import {ScriptedQuickChat} from "@/helper/chat/scriptedMessage";
import {fuzzyLinkParsing, relogByChatInput} from "@/helper/auth";

const markdown = new MarkdownIt('zero', {
        breaks: false // css whitespace
    })
    .enable(['emphasis','list'])

/*
init
 */

const chatInputRef : Ref<typeof ChatInput | undefined> = ref(undefined)

const router = useRouter();
const chatStore = useChatStore();
const authStore = useAuthStore();

const aInesRequestStore = useAInesRequestStore();
const blockButtonLabel = ref()

const onToggleVoiceTextMode = () => {
    if(chatStore.voiceOrTextMode == "text"){
        chatStore.setVoiceOrTextMode("voice")
    }else{
        chatStore.setVoiceOrTextMode("text")
    }
}


const employeeName : ComputedRef<string> = computed(() => {
    const employee = useEmployeeStore().storedEmployee

    const parts = employee?.name?.split(' ')

    if (parts && parts[0]) {
        return parts[0]
    }

    if (employee?.name) {
        return employee?.name
    }

    return '?'
})

const calendarModalOpen = ref(false)

const dayDetailModalOpen = ref(false)
const clickedDate: Ref<kapitelDateString|undefined> = ref(undefined)

useGlobalEmitter().on('notification:clicked', (dateId: kapitelDateString) => {
    console.log("clicked on notification with date: " + dateId)
    clickedDate.value = dateId
    dayDetailModalOpen.value = true
});

const checkForMagicLink = (message: string) : boolean => {
    const parsed = fuzzyLinkParsing(message);
    if(parsed !== ""){
        relogByChatInput(message)
        return true
    }
    return false
}

const chatSendsNewUserMessage = async (
    message: string,
    options: {
        messageExpertAssistant?: AInesAssistantType,
        prependedAssistantMessage?: string,
        prependExpertAssistant?: AInesAssistantType,
        richContentsPreview?: RichContent[],
        isScriptedContent?: boolean,
        checkForLoginLink?: boolean
    } = {
        messageExpertAssistant: undefined,
        prependedAssistantMessage: undefined,
        prependExpertAssistant: undefined,
        richContentsPreview: undefined,
        isScriptedContent: false,
        checkForLoginLink: false
    }
) : Promise<void> => {

    if (options.checkForLoginLink) {
       if (checkForMagicLink(message)) {
           return
       }
    }

    // TODO: centralize prepend 1
    if (!options.prependedAssistantMessage && aInesRequestStore.selection) {
        const request = aInesRequestStore.selection
        options.prependedAssistantMessage = request.message.text || undefined
        options.prependExpertAssistant = await requireAssistentForFeatureSet(request.featureSet)
    }

    // TODO: centralize prepend 3
    const chatStore = useChatStore()
    if (!options.prependedAssistantMessage && chatStore.scriptedMessage) {
        options.prependedAssistantMessage = chatStore.scriptedMessage.textContent
    }

    aInesRequestReset()

    return await sendUserMessage(
        message,
        {
            messageExpertAssistant: options.messageExpertAssistant,
            prependedAssistantMessage: options.prependedAssistantMessage,
            prependExpertAssistant: options.prependExpertAssistant,
            richContentsPreview: options.richContentsPreview,
            isScriptedContent: options.isScriptedContent
        }
    )
}

/*
TopSheet Handling
 */

const processes: AInesRichResponseType[] = [
    AInesRichResponseType.RegisterBookingSummary,
    AInesRichResponseType.AutopilotStartWizard,
    AInesRichResponseType.JobsiteSearch
]

const currentModalSelection : Ref<RichContent|undefined> = ref(undefined);
const processModalOpen = ref(false)
const onClickRichResponse = (content: RichContent)=>{
    if(processes.indexOf(content.type as AInesRichResponseType)>-1){
        currentModalSelection.value = content
        processModalOpen.value = true
    }
}
// --
const detailModalOpen = ref(false)
const currentDetailModalType : Ref<string|undefined> = ref(undefined);
const currentDetailModalInitData : Ref<object> = ref({});

const onRichResponseDetails = (data: object|string)  => {
    currentDetailModalType.value = data.type
    currentDetailModalInitData.value = data.initData
    detailModalOpen.value = true
}
///


/*
rendersets
 */

const today = date.getToday()

const RS_loader = computed(() => ({
    global: !!chatStore.globalLoaderState,
    globalMessage: chatStore.globalLoaderState,
    setup: !setUpAssistantDone.value
}))

const RS_show = computed(() => {
    const s = chatStore.chatStatus

    const show = {
        voiceMode: chatStore.voiceOrTextMode == "voice",
        textMode: chatStore.voiceOrTextMode == "text",

        // default: no loader shown
        messageLoaderDots: false,
        messageLoaderMessage: false,
        richResponseLoaderDots: false,

        // default: no message content shown
        messageText: false,
        messageRichResponses: false,

        // other modules
        ainesRequestSummary: false,
        ainesRequestBadge: false,

        // exploreQuickChats: false,
        // responseQuickChats: false,
        quickChats: false as 'explore' | 'requestResponse' | 'scripted' | false ,

        calendarButton: true
    }

    // TODO: role handling
    if (authStore.isCandidate() && !useCandidateStore().isPromoted) {
        show.calendarButton = false
    }

    if (aInesRequestStore.uiState === AInesRequestAssistantUIState.COMPACT) {
        show.ainesRequestSummary = true
    } else {
        show.ainesRequestBadge = true;
    }

    if (!show.ainesRequestSummary) {
        // message, loader & rich response - only if request summary not open
        if (aInesRequestStore.selection) {
            // selection always trumps regular message
            show.messageText = true
            show.messageRichResponses = true
            show.quickChats = 'requestResponse'
        } else if(chatStore.scriptedMessage) {
            show.messageText = true
            show.messageRichResponses = true
            show.quickChats = 'scripted'
        } else {
            if (chatStore.currentExpertRunMessageRichContentsPreview) {
                show.messageRichResponses = true
            }

            switch (true) {
                case (s === ChatStatus.READY):
                    break;
                case (s === ChatStatus.FAILED):
                    break
                case (s === ChatStatus.EXPECTING_NEXT_RESPONSE):
                case (s === ChatStatus.PREPENDING_ASSISTANT_MESSAGE):
                case (ChatStatus.isChoosing(s)):
                case (s === ChatStatus.EXPERT_SETUP):
                case (ChatStatus.isExperting(s) && s !== ChatStatus.EXPERT_RESPONDING):
                    // run in preparation, choosing, or pending -> loader dots
                    show.messageLoaderDots = chatStore.voiceOrTextMode=="text";
                    break;
                case (s === ChatStatus.EXPERT_RESPONDING):
                    // expert run pending - differentiate in reverse order: waiting, toolcalls, text streaming, tool call streaming, done
                    const recentToolCall = chatStore.currentExpertRunMostRecentToolCall
                    const recentMessageStatus = chatStore.currentExpertRunMessageStatus

                    if (recentMessageStatus === 'messageDone') { // done
                        show.messageText = true
                        show.messageRichResponses = true
                    } else if (recentMessageStatus === 'textDone') { // tool call streaming
                        show.messageText = true
                        if (!show.messageRichResponses) {
                            show.richResponseLoaderDots = true // text is done streaming, but message not - we may be waiting for RRs
                        }
                    } else if (recentMessageStatus === 'streaming') { // text streaming
                        show.messageText = true
                    }else if (recentToolCall) { // tool calls
                        show.messageLoaderMessage = true
                    } else { // waiting
                        show.messageLoaderDots = chatStore.voiceOrTextMode=="text"
                    }
                    break;
                case (s === ChatStatus.EXPERT_COMPLETE):
                    show.messageText = true
                    show.messageRichResponses = true

                    if (chatStore.scriptedQuickChats) {
                        show.quickChats = 'scripted'
                    }

                    break;
                default:
                    consoleErrorChat('unhandled Chat.vue status for %o', s)
                    break;
            }

            if (
                chatStore.nothingHappenedYet &&
                !aInesRequestStore.selection &&
                !show.ainesRequestSummary &&
                !chatStore.isBlocked &&
                setUpAssistantDone.value
            ) {
                show.quickChats = 'explore'
            }
        }
    }

    return show
})

const RS_assistantMessage: Ref<PreprocessedAssistantMessage | undefined> = computed(() => {

    let message = undefined

    // selection always trumps regular message
    if (aInesRequestStore.selection) {
        message = aInesRequestStore.selectedPreprocessedAssistantMessage
    } else if (chatStore.currentExpertRunMessageTextStreaming || chatStore.currentExpertRunMessageRichContents || chatStore.currentExpertRunMessageRichContentsPreview || chatStore.currentExpertRunMostRecentToolCallLoaderMessage) {
        message = {
            textContent: chatStore.currentExpertRunMessageTextStreaming || chatStore.currentExpertRunMostRecentToolCallLoaderMessage || undefined,
            richContents: chatStore.currentExpertRunMessageRichContents || chatStore.currentExpertRunMessageRichContentsPreview || undefined
        }
    } else if (chatStore.scriptedMessage){
        message = chatStore.scriptedMessage
    } else if (chatStore.currentExpertRunMostRecentToolCallLoaderMessage) {
        message = {
            textContent: chatStore.currentExpertRunMostRecentToolCallLoaderMessage,
            richContents: undefined
        }
    }
    
    return message
})

const RS_quickChats: Ref<ClickableQuickChat[]> = computed(() => {
    switch (RS_show.value.quickChats) {
        case 'explore': return exploreQuickChats.value
        case 'requestResponse': return requestResponseQuickChats.value
        case 'scripted': return scriptedQuickChats.value
    }

    return []
})


/**
 * chat input
 */

const chatInputText = ref('')
const onChatInputTextInput = (text: string) => {
    chatInputText.value = text
}
const onChatInputTextPaste = (text: string) => {
    checkForMagicLink(text)
}

/*
ExploreQuickActions
 */

const exploreQuickChats : Ref<ClickableQuickChat[]> = computed(() => {
    const result = chatInputText.value.trim() !== ''
        // show top 3 matches for search string
        ? getExploreQuickChatsMatching(chatInputText.value.trim(), 3)
        // show top 3 quick explores
        : getExploreQuickChatsTopN(3)

    // always reverse, showing top result at the bottom
    return result.reverse().map(qc => ({...qc, click: () => clickExploreQuickChat(qc)}))
})

const clickExploreQuickChat = async (qc:ExploreQuickChat) => {

    const expertAssistant = (qc.featureSet ? await requireAssistentForFeatureSet(qc.featureSet) : qc.expertAssistant) || undefined

    chatSendsNewUserMessage(
        qc.prompt ?? qc.label,
        {
            messageExpertAssistant: expertAssistant,
            richContentsPreview: qc.richContentsPreview || undefined,
            isScriptedContent: true
        }
    )

    useExploreQuickChatStore().trackUsage(qc)

    chatInputRef.value?.clear()
}



/*
AInesRequests & responseQuickActions
 */

// init AInesRequests
aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)

// trigger fetch for new requests
let aInesRequestInitialAutoOpened = false
const aInesRequestInit = () => {
    aInesRequestStore
        .refetch()
        .then(() => {
            // unfold into compact automatically - only if nothing happened yet
            if (
                aInesRequestStore.requestsLength > 0 &&
                chatStore.nothingHappenedYet
            ) {
                aInesRequestInitialAutoOpened = true
                aInesRequestStore.setUIState(AInesRequestAssistantUIState.COMPACT)
            }
        });
}
aInesRequestInit()
useGlobalEmitter().on("AppStateChanged", ({state, impersonationUserSwitch}) => {
    if (state === 'employee' && impersonationUserSwitch) {
        aInesRequestInit()
    }
})

// close again if automatically opened
watch(() => chatStore.nothingHappenedYet, () => {
    if (aInesRequestInitialAutoOpened) {
        aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    }
})

const aInesRequestReset = () => {
    aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    aInesRequestStore.selectRequest(undefined)
}

// when user starts typing or a system message arrives
watch(() => chatStore.chatStatus, () => {
    if (!ChatStatus.isPending(chatStore.chatStatus)) {
        aInesRequestReset()
    }
})

const onAInesRequestAvatarClick = () => aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
const onAInesRequestSelect = (request: AInesRequest) => {
    aInesRequestStore.setUIState(AInesRequestAssistantUIState.MINIMIZED)
    aInesRequestStore.selectRequest(request)
}

const requestResponseQuickChats : ComputedRef<ClickableQuickChat[]> = computed(() => {
    if (!aInesRequestStore.selection) {
        return []
    }
    const s = aInesRequestStore.selection;
    return getRequestResponseQuickChats(aInesRequestStore.selection)
        .map(qc => ({...qc, click: () => clickRequestResponseQuickChat(s, qc)}))
})

const clickRequestResponseQuickChat = async (request: AInesRequest, response:ResponseQuickChat) => {
    if (chatStore.isBlocked) {
        return;
    }

    switch (response.type) {
        case 'suggestedReaction':
            if (response.action) {
                aInesRequestStore.respondToSelectedRequest(response.action.reactionType)
                if (response.action.prompt) {
                    // TODO: centralize prepend 2
                    const expert = await requireAssistentForFeatureSet(request.featureSet)
                    chatSendsNewUserMessage(
                        response.action.prompt,
                        {
                            messageExpertAssistant: expert,
                            prependedAssistantMessage: request.message.text || undefined,
                            prependExpertAssistant: expert,
                            isScriptedContent: true
                        }
                    )
                }
            }
            break

        case 'cancel':
            aInesRequestStore.selectRequest(undefined)
            break

        case 'snoozeButtons':
            // show "snooze" action sheet
            await snoozeQuickChatHandler()
            break
    }
}

const scriptedQuickChats : ComputedRef<ClickableQuickChat[]> = computed(() => {
    if (chatStore.scriptedMessage) {
        return (chatStore.scriptedMessage?.suggestedQuickChats || []) as ScriptedQuickChat[]
    }
    if (chatStore.scriptedQuickChats) {
        return chatStore.scriptedQuickChats
    }
    return []
})


setUpAssistant()
</script>

<style scoped lang="scss">

.chat-container{
    margin-bottom: 5em;
    width: 100%;
    position: relative;
}
.response-container {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: flex-start;
}

.chat-input-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    position: relative;

    > * {
        min-height: 3.6rem;
    }

    > .chat-text-input {
        flex: 1 1 auto;
        margin-right: 0.5rem;
        order: 1;
    }

    > ion-button {
        background-color: var(--ion-color-white);
        flex: 0 0 3.6em;
        width: 3.6rem;
        border-radius: 1em;
        margin-left: 0.5rem;
        margin-top: 0;
        margin-bottom: 0;
        order: 2;
    }
}

.rich-response-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;

    > *:not(:last-child) {
        margin-bottom: 0.5em;
    }
}

.system-message {
    align-self: flex-start;
}

.user-message {
    align-self: flex-end;
}

.footer-input {
    width: 100%;
    background-color: var(--ion-color-white);
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-bottom: calc(var(--ion-safe-area-bottom) + var(--custom-spacing-app-content-padding-vertical));
    padding-top: var(--custom-spacing-app-content-padding-vertical);

    display: flex;
    flex-direction: column;

    > ion-input {
        z-index: 1;
    }
}

.quick-access-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 0.5em;
    right: var(--custom-spacing-app-content-padding-horizontal);
    left: var(--custom-spacing-app-content-padding-horizontal);
    transform: translateY(-100%);
    transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    > .quick-access-item {
        display: flex;
        justify-content: center;
        width: 100%;

        :deep(ion-icon) {
            font-size: 1.2em;
            display: inline-block;
            vertical-align: text-bottom;
            margin-right: 0.5rem;
        }

        &:not(:last-child) {
            margin-bottom: 0.5em;
        }

        > div {
            flex: 0 0 auto;
            display: inline-block;
            font-size: var(--custom-font-size-small);
            color: var(--ion-color-black);
            padding: 0.9em 1.5em;
            border-radius: 1rem;
            background-color: var(--ion-color-white);
        }

        &:after {
            content: '';
            flex: 1;
            transition: flex 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &:nth-child(1):after { transition-delay: 0.02s; }
        &:nth-child(2):after { transition-delay: 0.04s; }
        &:nth-child(3):after { transition-delay: 0.06s; }
        &:nth-child(4):after { transition-delay: 0.08s; }
    }

    &.centered {
        top: -0.5em;
        align-items: center;

        > .quick-access-item {
            &:after {
                flex: 0;
            }
        }
    }
}

.impersonation ion-label {
    margin-left: 0.25em;
}

</style>

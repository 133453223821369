import {App} from 'vue';
import {env as envHelper} from '@/helper/env';
import * as Sentry from "@sentry/vue";
import {useAuthStore} from '@/store/auth';
import {useGlobalEmitter} from './emitter';
import {AppInfo} from '@capacitor/app';
import {ChatSessionConfig} from "@/helper/chat/chatStore";
import {getAppInfo} from "@/helper/version";
import {onKapitelError} from "@/helper/error";


let initialized = false;

const sentryEnabled = () => {
    const flag = import.meta.env.VITE_SENTRY_ENABLED
    return flag === 'true';
}

const updateUserContext = () => {
    if (!initialized) {
        return
    }

    const authStore = useAuthStore()
    
    const employeeName = authStore.isConsultant()
        ? authStore.getImpersonationUsername()
        : authStore.getCurrentUserIdentifier();

    const userName = authStore.getCurrentUserIdentifier()

    const userId = authStore.isConsultant()
        ? userName + ':' + employeeName
        : employeeName

    // use employee as user
    const scope = Sentry.getCurrentScope();
    scope.setUser({
        id: userId,
        username: userId,
    });

    // log real user context
    Sentry.setContext('realUser', {
        username: userName,
        roles: authStore.roles,
        consultant: authStore.isConsultant(),
        impersonating: authStore.isImpersonating(),
        impersonatingAs: authStore.getImpersonationUsername()
    });
}

const updateChatSessionContext = (config: ChatSessionConfig) => {
    if (!initialized) {
        return
    }

    // log real user context
    Sentry.setContext('chatSession', {
        ...config
    });
}

const updateVersionContext = (appInfo: AppInfo) => {
    if (!initialized) {
        return
    }
    Sentry.setContext('appInfo', {
        ...appInfo
    });
}

export const useSentry = async (app: App) => {
    if (!sentryEnabled()) {
        return;
    }

    const getVersionPromise = getAppInfo()

    getVersionPromise.then((appInfo) => {
        if (appInfo) {
            updateVersionContext(appInfo)
        }
    })

    const urls = [
        window.location.origin,
        import.meta.env.VITE_GRAPHQL_ENDPOINT,
        import.meta.env.VITE_REST_ENDPOINT,
        import.meta.env.VITE_AI_ENDPOINT,
        import.meta.env.VITE_LOGIN_ENDPOINT,
        import.meta.env.VITE_VERSION_ENDPOINT,
        import.meta.env.VITE_PING_ENDPOINT,
        import.meta.env.VITE_CANDIDATE_GENERATION_ENDPOINT,
    ];

    const release= (await getVersionPromise)?.version || undefined
    Sentry.init({
        release,
        app,
        dsn: "https://52c016c11d8e82f3c80717fa757b8b41@o4507033914245120.ingest.us.sentry.io/4507033918242816",
        environment: envHelper.getEnvironment(),
        integrations: [
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
                maskAllInputs: false,
                networkDetailAllowUrls: urls,
            }),
            Sentry.httpClientIntegration()
        ],
        // Session Replay
        replaysSessionSampleRate: 1, // 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        
        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
    });

    initialized = true;

    updateUserContext();
    useGlobalEmitter().on("AppStateChanged", updateUserContext)

    useGlobalEmitter().on("ChatSessionConfigUpdated", updateChatSessionContext)

    onKapitelError(sentryCaptureException)

    //@ts-ignore
    window.SENTRY_ERROR_TEST = () => { throw new Error("Sentry Error Test") }
}

const sentryCaptureException = (exception: any) => {
    Sentry.captureException(exception)
}

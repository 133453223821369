<script setup lang="ts">
import {kapitelDateString} from '@/graphql/kapitelTypes'
import {IonChip} from '@ionic/vue'
import date from '@/helper/datetime/date'
import {computed, ComputedRef, ref, Ref} from 'vue'
import {MonthMetaData} from './types';
import {pluralize} from '@/helper/amountFormatter';
import Skeleton from '@/components/Skeleton.vue';
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";
import AutopilotJobsitePreference from "@/views/components/Planning/AutopilotJobsitePreference.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";

const props = defineProps<{
    month: kapitelDateString,
    data: MonthMetaData | undefined,
}>()

const monthState = computed(() => {
    switch (true) {
        case props.data?.isPlanning:
            return 'planningPending'
        case props.data?.isBooking:
            return 'planningRunning'
        case props.data?.isTimeTracking:
            return 'timeTracking'
        case props.data?.isPast:
            return 'done'
        default:
            return undefined
    }
})

const subheading : ComputedRef<{text: string, button?: {label: string, click: any}}>= computed(() => {
    const availabilityCount = props.data?.numAvailabilities || 0
    const bookingCount = props.data?.numBookings || 0
    const untrackedCount = props.data?.numUntrackedPastBookings || 0
    const bookingTarget = props.data?.targetBookings || 0
    const jobsiteCount = props.data?.jobsiteCount || 0

    let text = ''
    let button = undefined

    if (props.data?.isContractStart) {
        text += 'Vertragsanfang, '
    }

    switch (monthState.value) {
        case 'planningPending':
            text += `Dienstplanung noch nicht gestartet, ${pluralize(availabilityCount, 'Tag', 'Tage', 'bislang kein')} verfügbar.`
            button = {
                label: "Jetzt Starten",
                click: () => {openStartPlanningModal.value = true}
            }
            break
        case 'planningRunning':
            text +=  `Dienstplanung läuft: `
            text += `${bookingCount} von ${bookingTarget} Diensten bereits geplant`
            text += `, ${pluralize(availabilityCount, 'Tag', 'Tage', 'bislang keine')} verfügbar`
            text += `, ${pluralize(jobsiteCount, 'Station', 'Stationen')} aktiv - `
            button = {
                label: "anpassen",
                click: () => {openJobsitePreferencesModal.value = true},
            }
            break
        case 'timeTracking':
            text = `Zeiterfassung: ${pluralize(untrackedCount, 'Dienst', 'Dienste')} Dienste noch ohne Unterschrift.`
            button = {
                label: `Jetzt unterschreiben lassen`,
                click: () => {openTimesheetProcessModal.value=true}
            }
            break
        case 'done':
            text = 'Abgeschlossen'
            break
    }

    return {
        text,
        button,
    }
})


const monthLabel = computed(() => date.formatKapitelDate(props.month, 'MMMM'))

const openTimesheetProcessModal: Ref<boolean> = ref(false)
const openJobsitePreferencesModal: Ref<boolean> = ref(false)
const openStartPlanningModal: Ref<boolean> = ref(false)
</script>

<template>
<div
    class="calendar-month"
>
    <h1 class="custom-ricarda no-margin-left">
        {{ monthLabel }}
    </h1>
    <Skeleton v-if="!props.data" :items="['chip']" />
    <template v-if="props.data && (subheading.button || subheading.text)">
        <p @click="subheading.button?.click" class="subheading">
            {{subheading.text}}
            <strong v-if="subheading.button" class="clickable">{{subheading.button.label}} ></strong>
        </p>
    </template>

    <TimesheetProcess v-if="monthState==='timeTracking'" v-model="openTimesheetProcessModal"/>
    <AutopilotJobsitePreference v-if="monthState==='planningRunning'" v-model="openJobsitePreferencesModal" :month-id="props.month" />
    <StartPlanningMonth  v-if="monthState==='planningPending'" v-model="openStartPlanningModal" :month="props.month"></StartPlanningMonth>
</div>
</template>

<style lang="scss" scoped>
.calendar-month {
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-top: var(--custom-spacing-app-content-padding-vertical);
    padding-bottom: var(--custom-spacing-app-content-padding-vertical);

    ion-chip {
        margin-left: 0;
    }

    // white-space: nowrap; // to prevent chips from getting 2 lined
    .subheading {
        color: var(--color-text);

        .clickable {
            white-space: nowrap;
            color: var(--ion-color-primary);
        }
    }
}
</style>

import { mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import { Demand } from "@/graphql/generated/graphql";
import { jobsiteFragment } from "./jobsite";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {useGlobalEmitter} from "@/helper/emitter";
import date from "@/helper/datetime/date";
import datetime from "@/helper/datetime/datetime";



export async function addWishDemand(demand: Demand): Promise<any> {

    const MUTATION = gql`
        mutation AddWishDemand (
            $demandIdDings: Int!
        ) {
            addWishDemand(
                demandId: $demandIdDings,
            )
        }`

    const result = await mutation(
        MUTATION, {
            demandIdDings: demand.id,
        }
    )

    const response = result?.data?.addWishDemand

    if (!response) {
        return 'Fehler beim Speichern'
    }

    const date = datetime.convertDateTime2Date(demand.begin)
    useGlobalEmitter().emit('day:mutated', date)

    return response
}

export async function removeWishDemand(demand: Demand): Promise<any> {

    const MUTATION = gql`
            mutation RemoveWishDemand (
                $demandIdDings: Int!
            ) {
                removeWishDemand(
                    demandId: $demandIdDings,
                )
            }`

    const result = await mutation(
        MUTATION, {
            demandIdDings: demand.id,
        }
    )

    const response = result?.data?.removeWishDemand

    if (!response) {
        return 'Fehler beim Speichern'
    }

    const date = datetime.convertDateTime2Date(demand.begin)
    useGlobalEmitter().emit('day:mutated', date)

    return response
}

// Find all demands that match the current users wish list
// if a list of jobsite_ids is provided, only return demands that match the jobsite_ids
// otherwise return all demands that match the wish list
export const getWishDemand = async (jobsite_ids?: Array<number>|undefined): Promise<any> => {
    const result = await query(
        gql`${jobsiteFragment}
            query QueryWishDemand (
                $jobsite_ids: [Int!]
            ){
              wishDemand (
                jobsite_ids: $jobsite_ids
              ){
                id,
                begin,
                until,
                jobsite {
                    ...JobsiteFragment
                },
              }
        }`,
        {
            jobsite_ids: jobsite_ids || null
        }
    )

    return result?.data?.wishDemand
}

import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AInesEmployeeQualificationExperiencesType,
    AInesEmployeeQualificationSpecializationType,
    AInesEmployeeQualificationType,
    Candidate
} from "@/graphql/generated/graphql";
import {useGlobalEmitter} from "@/helper/emitter";
import {loginWithUniversalLink} from "@/helper/auth";

export async function fetchCandidate(): Promise<Candidate> {
    const result = await query(
        gql`query getCandidate {
            candidate {
                id
                name
                user {
                    id
                    userName
                }
                contact
                employeeQualification {
                    qualification
                    specialisation
                    experiences
                }
                promoted
                favoriteJobsites {
                    id
                    name
                }
                interestedIn {
                    name
                }
            }
        }`
    )

    return result.data.candidate
}

export const updateCandidateOnPromotion = async (
    name: string,
    contact: string,
    qualification: AInesEmployeeQualificationType,
    specialization?: AInesEmployeeQualificationSpecializationType,
    experiences?: Array<AInesEmployeeQualificationExperiencesType>
) => updateCandidate(
     name,
    contact,
    qualification,
    specialization,
    experiences,
    true
)

export const updateCandidateOnMissingJobsiteType = async (
    name: string,
    contact: string,
    interestedInMissingJobsiteType: string
) => updateCandidate(
    name,
    contact,
    undefined,
    undefined,
    undefined,
    undefined,
    interestedInMissingJobsiteType
)

export async function updateCandidate(
    name: string,
    contact: string,
    qualification?: AInesEmployeeQualificationType,
    specialization?: AInesEmployeeQualificationSpecializationType,
    experiences?: Array<AInesEmployeeQualificationExperiencesType>,
    promoted?: boolean,
    interestedInMissingJobsiteType?: string
): Promise<Candidate> {
    const result = await mutation(gql`         
            mutation UpdateCandidate(
                $name: String!,
                $contact: String!,
                $qualification: AInesEmployeeQualificationType
                $specialization: AInesEmployeeQualificationSpecializationType,
                $experiences: [String!],
                $promoted: Boolean,
                $interestedInMissingJobsiteType: String
            ) {
                updateCandidate(
                    name:$name,
                    contact: $contact,
                    qualification: $qualification,
                    specialization: $specialization,
                    experiences: $experiences,
                    promoted: $promoted,
                    interestedInMissingJobsiteType: $interestedInMissingJobsiteType
                ) {
                    id
                    name
                }
            }`, {
            name,
            contact,
            qualification,
            specialization,
            experiences: experiences ? Object.values(experiences) : null,
            promoted,
            interestedInMissingJobsiteType,
        }
    )

    useGlobalEmitter().emit('CandidateUpdated', result.data.updateCandidate)

    return result.data.updateCandidate
}

export async function setUpCandidate(): Promise<void> {
    const response = await fetch(
        import.meta.env.VITE_CANDIDATE_GENERATION_ENDPOINT,
        {
            // credentials: "include",
        }
    )
    if (!response.ok) {
        throw new Error(response.statusText)
    }

    const result = await response.json();
    if (!result.loginLink) {
        throw new Error("invalid result: " + JSON.stringify(result))
    }

    await loginWithUniversalLink(result.loginLink, false)
}

<script setup lang="ts">
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
    onIonViewWillEnter
} from '@ionic/vue'
import {useRouter} from 'vue-router'
import {computed, ref, Ref, watch} from 'vue'
import {fetchJobsiteDetail, getImageForJobsite} from '@/helper/jobsite'
import Contact from "@/views/CalendarDetail/components/Contact.vue"
import Address from "@/views/CalendarDetail/components/Address.vue"
import {DataLoader} from "@/helper/dataLoader.js";
import {Jobsite} from "@/graphql/generated/graphql";
import Skeleton from "@/components/Skeleton.vue";
import Modal from "@/components/Modal.vue"

const showEmptyFields = true

const jobsiteId = defineModel({type: Number})
const isOpen = ref()
watch(()=>jobsiteId.value, value =>{
    isOpen.value = !!value
    if(isOpen.value){
        if(jobsiteDetail.value){
            initialDataLoader.reload()
        }else{
            initialDataLoader.load()
        }
    }
})
watch(()=>isOpen.value, value=>{
    if(!value){
        jobsiteId.value = undefined
    }
} )
isOpen.value = !!jobsiteId.value


const router = useRouter()
const jobsiteDetail:Ref<Jobsite|undefined> = ref(undefined)
const fachbereiche = computed(() => jobsiteDetail.value ? jobsiteDetail.value.fachbereiche : [])

const initialDataLoader = new DataLoader([
    async () => { jobsiteDetail.value = jobsiteId.value ? await fetchJobsiteDetail(parseInt(jobsiteId.value)) : undefined },
])


onIonViewWillEnter(() => initialDataLoader.load())
const onModalDidPresent = () => initialDataLoader.load()
initialDataLoader.load()

</script>

<template>
    <Modal  v-model="isOpen" v-on:view:did-present="initialDataLoader.load" @view:didPresent="onModalDidPresent">
            <Skeleton v-if="initialDataLoader.pending.value" :items="['block--height-16rem']"></Skeleton>
            <div v-else>

            <!-- Image -->

            <img
                v-show="jobsiteDetail || showEmptyFields"
                class="teaser-image"
                :src="jobsiteDetail ? getImageForJobsite(jobsiteDetail) : ''"
                alt="jobsite image"
            />

            <!-- Jobsite -->

            <ion-card
                class="no-padding-top"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{jobsiteDetail?.abbreviation }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>

                        <ion-item>
                            <ion-label>
                                <h3>Name</h3>
                                <p>{{ jobsiteDetail?.name }}</p>
                                <p v-if="jobsiteDetail?.additionalAmenImportantAbbreviations?.length">
                                    <br>
                                    {{ jobsiteDetail?.additionalAmenImportantAbbreviations.join(", ") }}
                                </p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.medicalAbout || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Behandlungsspektrum</h3>
                                <p>{{ jobsiteDetail?.medicalAbout }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.jobsiteType || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Stationkategorie</h3>
                                <p>{{ jobsiteDetail?.jobsiteType?.map(jst => jst.name).join(', ') || 'keine' }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="fachbereiche || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Fachbereiche</h3>
                                <p>{{ fachbereiche?.map(fachbereich => fachbereich.name).join(', ') || 'keine' }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.enrichedAbout || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Beschreibung</h3>
                                <p>{{ jobsiteDetail?.enrichedAbout }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.qualificationAbout || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Tätigkeit</h3>
                                <p>{{ jobsiteDetail?.qualificationAbout }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.equipment || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Technische Ausstattung</h3>
                                <p>{{ jobsiteDetail?.equipment?.join(', ') || 'keine' }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.shiftTimes || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Schichten & Dienstzeiten</h3>
                                <p v-for="st in jobsiteDetail?.shiftTimes">{{ st }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.careRatio || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Betreuungsschlüssel</h3>
                                <p>{{ jobsiteDetail?.careRatio }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.bedCount || jobsiteDetail?.bedTypes || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Anzahl Betten</h3>
                                <p>{{ jobsiteDetail?.bedCount }} Betten</p>
                                <p>{{ jobsiteDetail?.bedTypes }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item>
                            <ion-label>
                                <h3>Anforderungen</h3>
                                <p>{{ jobsiteDetail?.requirements }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item>
                            <ion-label>
                                <h3>Über uns</h3>
                                <p>{{ jobsiteDetail?.about }}</p>
                            </ion-label>
                        </ion-item>

                        <ion-item
                            v-if="jobsiteDetail?.contact || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Kontakt</h3>
                                <Contact v-if="jobsiteDetail?.contact" :contact="jobsiteDetail.contact" />
                            </ion-label>
                        </ion-item>

                        <ion-item
                        v-if="jobsiteDetail?.address || showEmptyFields"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address v-if="jobsiteDetail?.address" :address="jobsiteDetail.address" />
                            </ion-label>
                        </ion-item>

                    </ion-list>
                </ion-card-content>
            </ion-card>

            <!-- House -->

            <ion-card
                v-if="jobsiteDetail?.house"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{ jobsiteDetail?.house?.name }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>
                        <ion-item
                            v-if="jobsiteDetail.house.address"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address :address="jobsiteDetail.house.address" />
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>

            <!-- Client -->

            <ion-card
                v-if="jobsiteDetail?.client"
            >
                <ion-card-header>
                    <ion-card-title>
                        {{ jobsiteDetail?.client?.name }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content
                    class="no-padding-top"
                >
                    <ion-list>
<!--                        <ion-item-->
<!--                            v-if="jobsiteDetail.client.contact" -->
<!--                        >-->
<!--                            <ion-label>-->
<!--                                <h3>Kontakt</h3>-->
<!--                                <Contact :contact="jobsiteDetail.client.contact" />-->
<!--                            </ion-label>-->
<!--                        </ion-item>-->
                        <ion-item
                            v-if="jobsiteDetail.client.address"
                        >
                            <ion-label>
                                <h3>Adresse</h3>
                                <Address :address="jobsiteDetail.client.address" />
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
                </ion-card>
            </div>
    </Modal>
</template>

<style scoped lang="scss">
.teaser-image {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    border-radius: 1rem;
}
</style>

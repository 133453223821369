import mitt, {Emitter} from 'mitt';
import {persistMessage, sendMessage, submitToolCallResponse} from "@/helper/chat/assistantAPI";

import {ResponseMessageRichResponse} from "@/helper/chat/richResponses";
import {isWriting, KapitelToolCall, performToolCall} from "@/helper/chat/toolCalls";
import {AInesAssistantType} from "@/graphql/generated/graphql";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";

import {StreamingThreadMessage, StreamingThreadMessageChanges, ThreadMessageStatus} from "@/helper/chat/threadMessage";
import {AssistantRunStatus} from "@/helper/chat/assistantRun/assistantRunStatus";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {makeMessagePersistParams} from "@/helper/chat/chatBL";
import {Stream} from "openai/streaming";
import {ChatCompletionChunk} from "openai/resources";
import {AssistantRun} from "@/helper/chat/assistantRun/assistantRun";
import {kapitelErrorHandler} from "@/helper/error";
import OpenAI from "openai";

export type ExpertAssistantRunEvents = {
    messageStreaming: StreamingThreadMessageChanges;
};

export class AssistantRunExpert extends AssistantRun {
    public mittExpert: Emitter<ExpertAssistantRunEvents>;

    public message : StreamingThreadMessage
    private toolCallsAcc : Array<OpenAI.ChatCompletionMessageToolCall> = []
    private messageAcc : string = ''

    constructor(
        public runConfig: AssistantRunConfig,
        protected userMessage: string,
        protected isScriptedContent: boolean,
        private optimisticExpertConfirmedPromise : Promise<boolean> | undefined = undefined
    ) {
        super(runConfig, userMessage, isScriptedContent);

        // init additional expert events
        this.mittExpert = mitt<ExpertAssistantRunEvents>();

        // init message
        this.message = new StreamingThreadMessage('', 'assistant')

        // TODO!
        // if there is a parallel expertChooser running confirming our existence we should react to a negative outcome
        if (this.optimisticExpertConfirmedPromise) {
            this.optimisticExpertConfirmedPromise
                .then(confirmed => {
                    if (!confirmed) {
                        consoleLogChat('%s: optimistic expert choice not confirmed - aborting %O',AInesAssistantType[this.runConfig.assistantType], this.runConfig);
                        this.resetCurrentStream()
                        this.setState(AssistantRunStatus.CANCELLED)
                    }
                })
        }


        this.submitMessageAndStream(userMessage, isScriptedContent)
            .then((stream: Stream<ChatCompletionChunk>) => {
                // start with first stream
                this.setCurrentStream(stream)
            })
            .catch(() => {
                // state: on content complete progress state from streaming > failed
                this.setState(AssistantRunStatus.FAILED)
                // streamComplete: event
                this.mitt.emit('runFailed')
            })

        this.setState(AssistantRunStatus.REQUESTED);

        // error handling
        this.mitt.on('runFailed', () => {
            const error = 'Run ' + this.getRunId() + ' failed: ' /* + this.getLastError()?.code + ' - ' + this.getLastError()?.message */
            // log to chat console
            consoleErrorChat(error, this)

            // handle error (toast, sentry)
            kapitelErrorHandler(error)
        })




    }

    /*
    multi stream handling
     */

    private streams : Stream<ChatCompletionChunk>[] = []
    private currentStream: Stream<ChatCompletionChunk> | undefined = undefined
    protected resetCurrentStream() {
        if (this.currentStream) {
            this.currentStream.controller.abort()
            this.currentStream = undefined
        }
    }
    protected setCurrentStream(stream: Stream<ChatCompletionChunk>) {
        if (this.currentStream) {
            throw new Error('already currentStream attached - reset first')
        }

        consoleLogChat('%s: receiving response stream for userMessage "' + this.userMessage + '" on %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)

        // reset TCs
        this.toolCallsAcc = []
        // reset content/msg
        this.messageAcc = ''
        this.updateMessage('', undefined,'ready')

        this.processStream(stream)
        this.currentStream = stream

        // collect stream
        this.streams.push(stream)
    }


    /*
    stream event management: attaches & detaches local stream event listeners

    private event handler bound to this by using =>
    referenced by on & off event management
     */

    /*
   stream event management: attaches & detaches local stream event listeners

   private event handler bound to this by using =>
   referenced by on & off event management
    */

    protected firstEventDone = false
    protected async processStream(stream: Stream<ChatCompletionChunk>) {
        for await (const chunk of stream) {

            const choice = chunk.choices[0];

            // first response event
            if (!this.firstEventDone) {
                this.mitt.emit('runResponseStarted')
                //state: progress state from INIT > RUNNING
                this.setState(AssistantRunStatus.RUNNING)
                this.firstEventDone=true
            }

            // accumulate message
            const contentDelta = choice.delta.content
            if (contentDelta) {
                this.messageAcc += contentDelta
                this.handleMessageStreamDiff(contentDelta)
            }
            // accumulate tc
            const toolCallsDelta = choice.delta.tool_calls || [] as OpenAI.ChatCompletionChunk.Choice.Delta.ToolCall[];
            for (const toolCallDelta of toolCallsDelta) {
                const { index } = toolCallDelta;

                if (!this.toolCallsAcc[index]) {
                    this.toolCallsAcc[index] = toolCallDelta as OpenAI.ChatCompletionMessageToolCall;
                }


                this.toolCallsAcc[index].function.arguments += toolCallDelta.function?.arguments;
            }

            // stop reasons
            switch (choice.finish_reason){
                case "stop":
                    this.handleMessageStreamDone()

                    // state: on content complete progress state from streaming > completed
                    this.setState(AssistantRunStatus.COMPLETED)
                    // streamComplete: event
                    this.mitt.emit('runComplete')
                    break;

                case 'length':
                    // state: on content complete progress state from streaming > failed
                    this.setState(AssistantRunStatus.FAILED)
                    // streamComplete: event
                    this.mitt.emit('runFailed')
                    break;

                case 'tool_calls':
                    await this.handleRunRequiresToolCalls()
                    break;
            }
        }
    }


    /*
    text streaming & message assembly

    streams responseMessage.text - on oaiTextDelta look for 'text:' & '"' and take everything in between as streamed text
     */

    private updateMessage(text: string, richResponses: ResponseMessageRichResponse[] | undefined, status: ThreadMessageStatus | undefined = undefined) {
        const updatedAttributes = {} as StreamingThreadMessageChanges

        if (this.message.text !== text) {
            this.message.text = text
            updatedAttributes.text = text
        }

        if (this.message.richResponses !== richResponses) {
            this.message.richResponses = richResponses
            updatedAttributes.richResponses = richResponses
        }

        if (status && this.message.status !== status) {
            this.message.status = status
            updatedAttributes.status = status
        }

        this.mittExpert.emit('messageStreaming', updatedAttributes)
    }


    private handleMessageStreamDiff(currentDelta: string = '') {
        // state handling for text attribute streaming
        if(this.message.status === 'streaming' && currentDelta.includes('"')){
            // end of text attribute - update only status
            this.updateMessage(this.message.text, undefined,'textDone')
        } else if (this.message.status === 'ready' && (this.messageAcc.endsWith('"text": "') || this.messageAcc.endsWith('"text":"'))) {
            // beginning of text attribute - reset text and move status to streaming
            this.updateMessage('', undefined,'streaming')
        } else if (this.message.status === 'streaming') {
            let existingText = this.message.text;
            let appendDiff = currentDelta
            if (existingText.length > 2) {
                const lookBackTwo = existingText.substring(existingText.length-2,existingText.length)
                appendDiff = lookBackTwo + appendDiff
                existingText = existingText.substring(0, existingText.length-2)
            }

            appendDiff = appendDiff.replace(/\\n/g, "\n")

            // append delta of text attribute
            this.updateMessage(existingText + appendDiff, undefined)
        }
    }

    private handleMessageStreamDone() {
        // responseMessage.text, responseMessage.richResponse on content complete set final text & rich response
        let message
        try {
            message = JSON.parse(this.messageAcc)
        } catch {
            message = undefined
        }

        consoleLogChat('%s: response message stream completed for userMessage "' + this.userMessage + '" on %O: %O',AInesAssistantType[this.runConfig.assistantType], this.runConfig, message || this.messageAcc)

        const text = message?.text || ''
        const richResponses = message?.richResponses || []

        sendMessage(
            this.messageAcc,
            this.runConfig.threadId,
            "assistant"
        ).then(() => {
            makeMessagePersistParams({
                config: this.runConfig,
                role: "assistant",
                content: text,
                runId: this.getRunId(),
                richResponses: richResponses
            }).then(persistMessage)
        })



        this.updateMessage(text, richResponses, 'messageDone')
    }


    /*
    tool call handling
     */

    private toolCalls : KapitelToolCall[] = []

    public getMostRecentToolCall() : KapitelToolCall | undefined {
        return this.toolCalls.length > 0 ? this.toolCalls[this.toolCalls.length-1] : undefined
    }

    private async handleRunRequiresToolCalls() {
        const toolCalls = this.toolCallsAcc

        const kapitelToolCalls = toolCalls.map(toolCall => ({
            id: toolCall.id,
            function: toolCall.function.name,
            arguments: toolCall.function.arguments
        }))

        kapitelToolCalls.forEach(kapitelToolCall => {
            // collect
            this.toolCalls.push(kapitelToolCall)
            // emit event
            this.mitt.emit('runRequiresToolCall', kapitelToolCall)
        })
        this.mitt.emit('runRequiresToolCalls', kapitelToolCalls)

        this.setState(AssistantRunStatus.PENDING_TOOL_CALL)

        // if there is a parallel expertChooser running confirming our existence we should wait for it
        if (
            this.optimisticExpertConfirmedPromise &&
            this.toolCalls.some(isWriting)
        ) {
            consoleLogChat('%s: optimistic expert run requires writing tool calls. Deferring: %O',AInesAssistantType[this.runConfig.assistantType], this.toolCalls.filter(isWriting))
            // wait for choice and don't execute tool calls if choice is negative
            if (!(await this.optimisticExpertConfirmedPromise)) {
                return
            }
        }

        // detach current stream - we are about to switch and in the meantime this run shouldn't do anything
        this.resetCurrentStream()

        // log tool call messages
        makeMessagePersistParams({
            config: this.runConfig,
            runId: this.getRunId(),
            role: "assistant",
            toolCalls: toolCalls
        }).then(persistMessage)


        // resolve tool calls
        const toolCallResponses: any[] = await Promise.all(
            kapitelToolCalls.map(
                async (kapitelToolCall: KapitelToolCall) =>{
                    return {
                        tool_call_id: kapitelToolCall.id,
                        output: await performToolCall(kapitelToolCall)
                    }
                }
            )
        )


        const nextStream = await this.submitToolCallOutputsAndStream(kapitelToolCalls, toolCallResponses)
        this.setCurrentStream(nextStream)
        this.setState(AssistantRunStatus.RUNNING)

    // case chunk.stop_reason === "function_call":
    //     // state: on content complete progress state from streaming > requires_action
    //     this.setState(AssistantRunStatus.REQUIRES_ACTION)
    //     // tool call: event
    //     this.mitt.emit('runRequiresToolCall', chunk.choices[0].delta.function_call)
    //     break;
    }




    protected submitToolCallOutputsAndStream(kapitelToolCalls: KapitelToolCall[], toolCallOutputs: Array<{output: string, tool_call_id: string}>) : Promise<Stream<OpenAI.ChatCompletionChunk>> {
        consoleLogChat('%s: sending ' + toolCallOutputs.length + ' tool call output(s) to %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)
        toolCallOutputs.forEach((output) => {
            makeMessagePersistParams({
                config: this.runConfig,
                role: "tool",
                content: output.output,
                toolCallId: output.tool_call_id,
                runId: this.getRunId(),
            }).then(persistMessage)
        })

        return submitToolCallResponse(
            kapitelToolCalls,
            toolCallOutputs,
            this.runConfig,
            this.getRunId(),
            true
        )
    }
}

import {AInesAssistantType} from "@/graphql/generated/graphql";

import {fetchWithAuth} from "@/client";


import OpenAI from "openai";
import {consoleErrorChat} from "@/helper/console";
import {kapitelErrorHandler} from "@/helper/error";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {getOpenAIApiKey, makeMessagePersistParams} from "@/helper/chat/chatBL";
import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {KapitelToolCall} from "@/helper/chat/toolCalls";
import {useAuthStore} from "@/store/auth";
import {Stream} from "openai/streaming";
import {v4 as uuidv4} from "uuid";


const openaiClient = async() : Promise<OpenAI> => {
    const endpoint = import.meta.env.VITE_AI_ENDPOINT
    let key: string = "";
    const parsedUrl = new URL(endpoint); // Parse the URL
    if(parsedUrl.hostname === "api.openai.com"){
        key = await getOpenAIApiKey()
    }else{
        key = useAuthStore().getToken() ?? ""
    }

    return new OpenAI(
        {
            baseURL: endpoint,
            apiKey: key,
            // apiKey,
            dangerouslyAllowBrowser: true,
            maxRetries: 2,
            timeout: 60*1000
        }
    );
}

type PromptInfo =  {
    prompt: string,
    model: string,
    tools: [],
    response_format: OpenAI.ResponseFormatText | OpenAI.ResponseFormatJSONObject | OpenAI.ResponseFormatJSONSchema | undefined
}

export type MessagePersistParameters = {
    assistant: string,
    assistantType: AInesAssistantType,
    sessionId: string,
    threadId: string,
    pingPongId: string,
    runId: string|undefined,
    role: "assistant"|"user"|"tool",
    content: string,
    toolCalls: any,
    toolCallId: string|undefined,
    richResponses: any,
    isScriptedContent: boolean,
    isVoiceMode: boolean,
}

const apiErrorHandler = (e: any, kapitelErrorHandlerAlreadyHandled = false) => {
    // log to chat console
    consoleErrorChat(e)

    // handle error (toast, sentry)
    if (!kapitelErrorHandlerAlreadyHandled) {
        kapitelErrorHandler(e)
    }
}

const assistantStore: Map<string, PromptInfo> = new Map<string, PromptInfo>()

export const createAssistant = async (assistantType: AInesAssistantType): Promise<string> => {
    let response
    try {
        response = await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + '/aines/get-assistant-prompt' + '?' + assistantType,
            JSON.stringify({
                assistant_type: assistantType,
            }),
            'POST',
            {
                "Content-Type": 'application/json',
            }
        )

    } catch (e: any) {
        apiErrorHandler(e, true)
        throw e
    }

    const newPromptId = "prompt_" + uuidv4()

    const prompt:PromptInfo = await response.json()

    assistantStore.set(newPromptId, prompt)

    return newPromptId

};


    const getAssistantFromStore = (assistantId: string): PromptInfo => {
    if(!assistantStore.has(assistantId)){
        throw new Error("Assistant not found: " + assistantId)
    }

    return assistantStore.get(assistantId) as PromptInfo
}




const threadStore = new Map<string, OpenAI.ChatCompletionMessageParam[]>()

/**
 *
 */
export const createThread = async (): Promise<string> => {

    const newThreadId = "thread_" + uuidv4()

    threadStore.set(newThreadId, [])

    return newThreadId

}

const addToThreadStore = (message: OpenAI.ChatCompletionMessageParam, threadId: string) => {

    if(!threadStore.has(threadId)){
        throw new Error("Thread not found: " + threadId)
    }

    threadStore.get(threadId)?.push(message)
}

const getMessagesFromThreadStore = (threadId: string, truncation=100): OpenAI.ChatCompletionMessageParam[] => {
    if(!threadStore.has(threadId)){
        throw new Error("Thread not found: " + threadId)
    }

    const messages = threadStore.get(threadId)

    return messages ? messages.slice(-truncation) : []
}


export const performToolCallRequest = async (toolCall: KapitelToolCall): Promise<any> => {
    try {
        const param = JSON.stringify({
            id: toolCall.id,
            type: 'function',
            function: {
                name: toolCall.function,
                arguments: toolCall.arguments,
            }
        })

        const response = await fetchWithAuth(
            import.meta.env.VITE_REST_ENDPOINT + '/aines/perform-tool-call',
            param,
            "POST"
        );
        if (!response?.body) {
            throw Error("No valid response given from perform-toolcall for: " + param)
        }

        return await response.text()
    } catch (e: any) {
        apiErrorHandler(e, true)
        throw e
    }
}


export const submitToolCallResponse = async <B extends boolean>(toolCalls: KapitelToolCall[], toolCallOutputs: Array<{output: string, tool_call_id: string}>, runConfig: AssistantRunConfig, runId: string, stream = true as B) : Promise<B extends true ? Stream<OpenAI.ChatCompletionChunk> : OpenAI.ChatCompletion> => {

    const tcMessage = {
        role: 'assistant',
        tool_calls : toolCalls.map(tc => {
            return {
                id: tc.id,
                function: {
                    arguments: tc.arguments,
                    name: tc.function
                },
                type: 'function'
            } as OpenAI.ChatCompletionMessageToolCall
        })
    } as OpenAI.ChatCompletionAssistantMessageParam

    addToThreadStore(tcMessage, runConfig.threadId)

    const  message: OpenAI.ChatCompletionToolMessageParam = {
        content: toolCallOutputs[0].output,
        tool_call_id: toolCallOutputs[0].tool_call_id,
        role: "tool"
    }

    addToThreadStore(message, runConfig.threadId)

    return runThread(runConfig.assistantId, runConfig.threadId, await runConfig.getAdditionalInstructions(), stream)
}

//// -

export async function sendMessageAndRun<B extends boolean>(
    config: AssistantRunConfig,
    content: string,
    isScriptedContent: boolean,
    stream = true as B
):Promise<B extends true ? Stream<OpenAI.ChatCompletionChunk> : OpenAI.ChatCompletion> {

    await sendMessage(content, config.threadId)

    makeMessagePersistParams({
        config: config,
        role: "user",
        content: content,
        isScriptedContent: isScriptedContent
    }).then(persistMessage)

    console.warn(config)
    return runThread(config.assistantId, config.threadId, await config.getAdditionalInstructions(), stream)
}

async function runThread<B extends boolean>(assistantId: string, threadId: string, additionalInstructions: string | undefined, stream: boolean) : Promise<B extends true ? Stream<OpenAI.ChatCompletionChunk> : OpenAI.ChatCompletion> {
//Promise<> {
    try {

        const assistant = getAssistantFromStore(assistantId)
        const messages: OpenAI.ChatCompletionMessageParam[] = getMessagesFromThreadStore(threadId)

        const assistantMessage = {
            role: 'developer',
            content: assistant.prompt + '\n\n' + additionalInstructions
        } as OpenAI.ChatCompletionDeveloperMessageParam

        const messageCreateParams: OpenAI.ChatCompletionCreateParamsStreaming | OpenAI.ChatCompletionCreateParams = {
            model: assistant.model,
            messages:[assistantMessage as OpenAI.ChatCompletionMessageParam].concat(messages),
            tools: assistant.tools && assistant.tools.length > 0 ? assistant.tools : undefined,
            response_format: assistant.response_format,
            stream
        }

        /* @ts-ignore */
        return (await openaiClient()).chat.completions.create(messageCreateParams)

    } catch (e: any) {
        apiErrorHandler(e, false)
        throw e
    }


}

export const sendMessage = async (
    content: string,
    threadId: string,
    role: 'user' | 'assistant' = 'user',
) => {

    const message = {
        content: content,
        role: role
    } as OpenAI.ChatCompletionUserMessageParam|OpenAI.ChatCompletionAssistantMessageParam

    addToThreadStore(message, threadId)
};


export const tts = async (payload: string): Promise<any> => {
    try {
        // Prepare the request payload
        const requestPayload = {
            model: 'gpt-4o-mini-audio-preview-2024-12-17',
            modalities: ['text', 'audio'],
            audio: {
                voice: 'shimmer',
                format: 'pcm16'
            },
            messages: [
                {
                    role: 'user',
                    content: 'Sage folgenden Text: "'+payload+'"',
                }
            ],
            stream: true
        };

        // Send the request to the OpenAI Chat API
        return (await openaiClient()).chat.completions.create(requestPayload as {model: any, modalities: any, audio: any, messages: any});
    } catch (error) {
        console.error('Error generating audio response:', error);
        throw error;
    }
}


/**
 * wraps base64 in file and calls openai API via transcribeAudioFile()
 */
export const transcribeAudioBase64 = async (b64Audio: string, mimeType: string): Promise<string | undefined> => {

    const base64ToBlob = (base64: string, mime: string): Blob => {
        const byteChars = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteChars.length; offset += 512) {
            const slice = byteChars.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: mime});
    }

    let filename = "audio.webm"

    if (mimeType === 'audio/mp4') {
        filename = "audio.mp4"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    } else if (mimeType === 'audio/aac') {
        filename = "audio.m4a"
        alert(mimeType + ' unsupported by OpenAI API - use Chrome browser or native app')
        return;
    }

    const audioFile = new File([base64ToBlob(b64Audio, mimeType)], filename, {type: mimeType});

    return await transcribeAudioFile(audioFile)
}

/**
 * helper list
 */
const transcriptionIgnoreList = [
    "Untertitelung aufgrund der Amara.org-Community",
    "Untertitel im Auftrag des ZDF für funk, 2017",
    "Untertitel von Stephanie Geiges",
    "Untertitel der Amara.org-Community",
    "Untertitel im Auftrag des ZDF, 2017",
    "Untertitel im Auftrag des ZDF, 2020",
    "Untertitel im Auftrag des ZDF, 2018",
    "Untertitel im Auftrag des ZDF, 2021",
    "Untertitelung im Auftrag des ZDF, 2021",
    "Copyright WDR 2021",
    "Copyright WDR 2020",
    "Copyright WDR 2019",
    "SWR 2021",
    "SWR 2020",
    "Vielen Dank für's Zuschauen!",
    "Bis zum nächsten Mal."
]

/**
 * helper function
 */

/**
 *
 */
const transcribeAudioFile = async (file: File): Promise<string | undefined> => {
    const formData = new FormData();
    formData.append("file", file);

    const maxNumberOfTries: number = 3
    let currentNumberOfTries: number = 0
    let transcriptionText = undefined;
    while (!transcriptionText  && currentNumberOfTries<maxNumberOfTries){
        try {
            const transcription = await fetchWithAuth(
                import.meta.env.VITE_REST_ENDPOINT + '/aines/transcription',
                formData,
                "POST"
            )

            const transcriptionTextObject = await transcription.text();
            transcriptionText = (await JSON.parse(transcriptionTextObject))?.text || '';
        } catch (e: any) {
            console.log("transcription failed " + (currentNumberOfTries+1).toString() + " times.")
            transcriptionText = undefined
            apiErrorHandler(e, true)
            throw e
        }
        currentNumberOfTries++;
    }
    if(!transcriptionText){
        console.log("transcription failed.")
        return undefined
    }

    // mostly on empty audio translations we get strange responses - this fixes those cases
    const removeIgnoredTranscriptions = (transcription: string) => {
        transcriptionIgnoreList.forEach(ignoreString => {
            transcription = transcription.replace(new RegExp(ignoreString, 'g'), '');
        });
        return transcription;
    };

    // Example usage:
    transcriptionText = removeIgnoredTranscriptions(transcriptionText).trim();

    return transcriptionText
};

export const persistMessage = async (
   params: MessagePersistParameters
): Promise<any> => {
    const response = await fetchWithAuth(
        import.meta.env.VITE_REST_ENDPOINT + '/aines/persist-message' + '?' + params.assistantType,
        JSON.stringify({
            assistant: params.assistant,
            assistantType: params.assistantType,
            sessionId: params.sessionId,
            threadId: params.threadId,
            pingPongId: params.pingPongId,
            runId: params.runId,
            role: params.role,
            content: params.content,
            toolCalls: params.toolCalls,
            toolCallId: params.toolCallId,
            richResponses: params.richResponses,
            isScriptedContent: params.isScriptedContent,
            isVoiceMode: params.isVoiceMode
        }),
        "POST"
    );
    if (!response?.body) {
        throw Error("No valid response given.")
    }

    return await response.text()
}

export const getApiKey = async () => {
    const result = await query(gql`
        query GetOpenaiApiKey{
            openaiApiKey 
        }`
    )

    return result?.data.openaiApiKey
};

import {useAuthStore} from "@/store/auth";
import {useCandidateStore} from "@/store/candidate";
import date from "@/helper/datetime/date";
import datetime from "@/helper/datetime/datetime";
import {
    AInesEmployeeQualificationExperiencesType,
    AInesEmployeeQualificationSpecializationType,
    AInesEmployeeQualificationType
} from "@/graphql/generated/graphql";

export const generateAdditionalInstructions = async () => {
    let result: string = ""


    // candidate infos

    if (useAuthStore().isCandidate()) {

        const candidateStore = useCandidateStore()
        const candidate = await candidateStore.ensureCandidate()

        if (candidate.favoriteJobsites.length > 0) {
            result = result + "\n\nDie gemerkten Stationen des Nutzers sind:"
            candidate.favoriteJobsites.forEach((jobsite) => {
                result = result + '* ' + JSON.stringify(jobsite)
            })
        } else {
            result = result + "\n\nDer Nutzer hat bislang noch keine Stationen auf der Merkliste."
        }

        if(candidate.interestedIn) {
            result = result + "\n\nDer Nutzer interessiert sich für die folgenden Stationskategorie: " + JSON.stringify(candidate.interestedIn)
        }
        if(candidate.employeeQualification) {

            const inferredInterestedIn = inferFromQuali(
                candidate.employeeQualification.qualification as AInesEmployeeQualificationType,
                candidate.employeeQualification.specialisation as AInesEmployeeQualificationSpecializationType|null,
                candidate.employeeQualification.experiences as Array<AInesEmployeeQualificationExperiencesType>
            )

            if(candidate.promoted && (candidate.interestedIn?.name !== inferredInterestedIn)){
                result = result = "\n\nDer Nutzer hat eine Qualifikation als '"
                    + candidate.employeeQualification.qualification
                    + "' angegeben und interessiert sich für die Stationskategorie '"
                    + inferredInterestedIn
                    + "'."
                    + "Das ist eine unübliche Kombination. Falls der Nutzer keine, oder wenige Dienstvorschläge bekommt," +
                    " weise ihn auf diesen Umstand hin und schlage ihm vor, sein Interessensgebiet auf '"+inferredInterestedIn+"' anzupassen."
            }
        }
    }


    // current clock

    result = result + "\n\nHeute ist das folgende Datum: " +
        date.getToday()
        + ".\n\nDie aktuelle Uhrzeit ist " + datetime.formatKapitelDateTime(
            datetime.getNow(),
            datetime.kapitelDateTimeFormatTime
        ) + "."

    return result
}


const inferFromQuali = (
    qualification: AInesEmployeeQualificationType,
    specialization: AInesEmployeeQualificationSpecializationType | null,
    experiences: Array<AInesEmployeeQualificationExperiencesType>
) => {
    // infer from quali

    if (qualification === AInesEmployeeQualificationType.Ota
        || experiences.includes(AInesEmployeeQualificationExperiencesType.Op)) {
        return "OP";
    } else if (
        (qualification === AInesEmployeeQualificationType.FachGkp
            && specialization === AInesEmployeeQualificationSpecializationType.Its)
        || experiences.includes(AInesEmployeeQualificationExperiencesType.Its)
        || experiences.includes(AInesEmployeeQualificationExperiencesType.Anaesthesie)
    ) {
        return "ITS";
    }

    return null;

}

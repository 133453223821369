import {AInesAssistantType} from "@/graphql/generated/graphql";
import {KapitelToolCall} from "@/helper/chat/toolCalls";
import {consoleLogChat} from "@/helper/console";
import mitt, {Emitter} from 'mitt';
import {sendMessageAndRun} from "@/helper/chat/assistantAPI";
import {AssistantRunStatus} from "@/helper/chat/assistantRun/assistantRunStatus";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {Stream} from "openai/streaming";
import {v4 as uuidv4} from "uuid";
import OpenAI from "openai";

export type AssistantRunEvents = {
    stateChanged: AssistantRunStatus,
    runRequiresToolCall: KapitelToolCall
    runRequiresToolCalls: KapitelToolCall[]
    runResponseStarted: void,
    runComplete: void,
    runFailed: void
};

export abstract class AssistantRun {
    public mitt : Emitter<AssistantRunEvents>;

    protected constructor(
        public runConfig: AssistantRunConfig,
        protected userMessage: string,
        protected isScriptedContent: boolean
    ) {
        // init event bus
        this.mitt = mitt<AssistantRunEvents>();

        // init fake run
        this.runId = 'run_' + uuidv4()

        // this.submitMessageAndStream(userMessage, isScriptedContent)
        //     .then((stream: Stream<ChatCompletionChunk>) => {
        //         this.setState(AssistantRunStatus.REQUESTED);
        //         // start with first stream
        //         this.setCurrentStream(stream)
        //     })
        //     .catch(() => {
        //         // state: on content complete progress state from streaming > failed
        //         this.setState(AssistantRunStatus.FAILED)
        //         // streamComplete: event
        //         this.mitt.emit('runFailed')
        //     })
        //
        // // error handling
        // this.mitt.on('runFailed', () => {
        //     const error = 'Run ' + this.getRunId() + ' failed: ' + this.getLastError()?.code + ' - ' + this.getLastError()?.message
        //     // log to chat console
        //     consoleErrorChat(error, this)
        //
        //     // handle error (toast, sentry)
        //     kapitelErrorHandler(error)
        // })
    }

    public onUnhandledStreamError(error: Error) {
        if (!AssistantRunStatus.isTerminal(this.state)) {
            debugger
            this.setState(AssistantRunStatus.FAILED);
        }
    }


    /**
     * run handling
     */

    // protected run: Run | undefined;
    protected runId: string;

    // public getLastError() : Run.LastError | null | undefined {
    //     return this.run?.last_error
    // }

    public getRunId() : string {
        return this.runId
    }


    /*
    state handling
     */

    private state : AssistantRunStatus = AssistantRunStatus.INIT;
    public getState() {
        return this.state;
    }
    protected setState(state: AssistantRunStatus) {
        const nextState = AssistantRunStatus.transitionTo(state, this.state)
        if (nextState === this.state) {
            return
        }

        this.state = nextState
        this.mitt.emit('stateChanged', nextState)
    }


    /*
    stream sources
     */

    protected submitMessageAndStream(message: string, isScriptedContent: boolean) : Promise<Stream<OpenAI.ChatCompletionChunk>> {
        consoleLogChat('%s: sending user message "' + message + '" to %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)

        return sendMessageAndRun(
            this.runConfig,
            message,
            isScriptedContent,
            true
        )
    }

    protected submitMessageAndNonStream(message: string, isScriptedContent: boolean) : Promise<OpenAI.ChatCompletion> {
        consoleLogChat('%s: sending user message "' + message + '" to %O', AInesAssistantType[this.runConfig.assistantType], this.runConfig)

        return sendMessageAndRun(
            this.runConfig,
            message,
            isScriptedContent,
            false
        )
    }



}

<template>
    <div>
        <RichResponseListItem
            iconType="text"
            title="Dienste aussuchen"
            :selectable="false"
            v-on:click="onClick"
            :button="true"
        />
        <InitCandidatePromotionModal ref='modal' v-model="modalValue"  v-on:submit="onSubmit" :useLastChatMessageAsIntroMessage="useLastChatMessageAsIntroMessage"/>
    </div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {PropType, ref, Ref} from "vue";
import InitCandidatePromotionModal from "@/views/components/InitCandidatePromotionModal.vue";
import {updateCandidateOnPromotion} from "@/helper/candidate";
import {appendMessage, onCandidatePromotion, presentScriptedMessage} from "@/helper/chat/chatBL";
import {useChatStore} from "@/helper/chat/chatStore";
import {scriptedMessages} from "@/helper/chat/scriptedMessage";
import {AInesAssistantType} from "@/graphql/generated/graphql";

const props = defineProps({
    useLastChatMessageAsIntroMessage: {
        type: Boolean as PropType<Boolean>,
        required: false,
        default: true
    },
    openModalImmediately: {
        type: Boolean,
        required: false,
        default: true
    }
})

const modal = ref()
const modalValue: Ref<Boolean> = ref(props.openModalImmediately)
const onClick = () => {
    openModal()
}

const openModal = () => {
    modalValue.value = true
    modal.value.init()
}


const onSubmit = (inputData: any) => {

    const name = inputData.fullName  ? inputData.fullName : ""
    const contact = inputData.phoneNumber  ? inputData.phoneNumber : ""
    const qualification = inputData.qualification ? inputData.qualification : []
    const specialization = inputData.specialization ? inputData.specialization : null
    const experiences = inputData.experiences ? inputData.experiences : []
    const promoted = inputData.acknowledgesContract && inputData.wantsEmployment

    useChatStore().setGlobalLoaderState(true, 'Eine Sekunde noch...')

    updateCandidateOnPromotion(
        name,
        contact,
        qualification,
        specialization,
        experiences
    )
        .then(() => onCandidatePromotion())
        .then(()=>{
            appendMessage("Vielen Dank, ich konnte über das Formular meine Absichtserklärung zur Anstellung abschicken.", AInesAssistantType.CandidateAssistant, 'user')
            presentScriptedMessage(scriptedMessages.afterPromotion)
        })
        .finally(() => {
            useChatStore().setGlobalLoaderState(false)
        })
}

const init = () =>{
    openModal()
}


</script>

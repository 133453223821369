/* eslint-disable */
import {kapitelDateTimeString, kapitelDateString, kapitelTimeString} from '../kapitelTypes'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar type represents time data, represented as an ISO-8601 encoded UTC date string. */
  DateTime: { input: kapitelDateTimeString; output: kapitelDateTimeString; }
  /** The `KapitelDateImmutable` scalar type represents a date (without time or timezone information), represented as an ISO-8601 encoded UTC date string. */
  KapitelDateImmutable: { input: kapitelDateString; output: kapitelDateString; }
  /** The `KapitelTimeImmutable` scalar type represents a time (without date or timezone information), represented as an ISO-8601 encoded UTC string, for example "08:00". */
  KapitelTimeImmutable: { input: kapitelTimeString; output: kapitelTimeString; }
};

export type AInesAssistantFeatureMapping = {
  __typename?: 'AInesAssistantFeatureMapping';
  assistant: AInesAssistantType;
  feature: AInesFeatureSet;
};

export enum AInesAssistantType {
  Availability = 'Availability',
  CandidateAssistant = 'CandidateAssistant',
  FeatureOverview = 'FeatureOverview',
  JobsitePreferences = 'JobsitePreferences',
  Meta = 'Meta',
  Planning = 'Planning',
  RegisterBooking = 'RegisterBooking',
  Roster = 'Roster',
  Timesheet = 'Timesheet'
}

export enum AInesEmployeeQualificationExperiencesType {
  Anaesthesie = 'Anaesthesie',
  Its = 'ITS',
  Op = 'OP',
  Rettungsstelle = 'Rettungsstelle'
}

export enum AInesEmployeeQualificationSpecializationType {
  Its = 'ITS'
}

export enum AInesEmployeeQualificationType {
  FachGkp = 'FachGKP',
  Gkp = 'GKP',
  Ota = 'OTA'
}

export enum AInesFeatureSet {
  Availability = 'Availability',
  DemandBrowser = 'DemandBrowser',
  FeatureOverview = 'FeatureOverview',
  InterestedIn = 'InterestedIn',
  JobsiteBrowser = 'JobsiteBrowser',
  JobsitePreferences = 'JobsitePreferences',
  PlanningMonth = 'PlanningMonth',
  PlanningTemplates = 'PlanningTemplates',
  PromoteCandidate = 'PromoteCandidate',
  RegisterBooking = 'RegisterBooking',
  Roster = 'Roster',
  ShiftTimes = 'ShiftTimes',
  Sickness = 'Sickness',
  Timeoff = 'Timeoff',
  Timesheet = 'Timesheet',
  Vacation = 'Vacation',
  WishDemand = 'WishDemand'
}

export enum AInesInterestedInJobsiteTypes {
  Its = 'ITS',
  Op = 'OP'
}

export enum AInesJobsiteListType {
  Alternative = 'Alternative',
  Favorite = 'Favorite',
  None = 'None'
}

export type AInesRequest = {
  __typename?: 'AInesRequest';
  featureSet: AInesFeatureSet;
  message: ResponseMessage;
  scope: Scalars['String']['output'];
  shortLabel: Scalars['String']['output'];
  subject: AInesRequestSubject;
  subjectLabel: Scalars['String']['output'];
  suggestions: Array<AInesRequestAction>;
  urgent: Scalars['Boolean']['output'];
};

export type AInesRequestAction = {
  __typename?: 'AInesRequestAction';
  prompt?: Maybe<Scalars['String']['output']>;
  reactionType: AInesRequestReactionType;
  shortLabel: Scalars['String']['output'];
};

export type AInesRequestReaction = {
  __typename?: 'AInesRequestReaction';
  reactionType: AInesRequestReactionType;
  requestScope: Scalars['String']['output'];
  requestSubject: AInesRequestSubject;
};

export type AInesRequestReactionInput = {
  reactionType: AInesRequestReactionType;
  requestScope: Scalars['String']['input'];
  requestSubject: AInesRequestSubject;
};

export enum AInesRequestReactionType {
  Dismissrequest = 'DISMISSREQUEST',
  Handled = 'HANDLED',
  Ignorerequestsubject = 'IGNOREREQUESTSUBJECT',
  Later = 'LATER'
}

export enum AInesRequestSubject {
  Availability = 'AVAILABILITY',
  CandidateJobsites = 'CANDIDATE_JOBSITES',
  Planning = 'PLANNING',
  Registerbooking = 'REGISTERBOOKING',
  Targethours = 'TARGETHOURS',
  Timesheet = 'TIMESHEET'
}

export enum AInesRichResponseType {
  AzkMeter = 'AZKMeter',
  AutopilotJobsitePreferences = 'AutopilotJobsitePreferences',
  AutopilotPlanningStatusSummary = 'AutopilotPlanningStatusSummary',
  AutopilotStartWizard = 'AutopilotStartWizard',
  CandidateMissingJobsiteType = 'CandidateMissingJobsiteType',
  DayCalendar = 'DayCalendar',
  DemandList = 'DemandList',
  InitCandidatePromotion = 'InitCandidatePromotion',
  InterestedInSet = 'InterestedInSet',
  JobsiteInfo = 'JobsiteInfo',
  JobsiteList = 'JobsiteList',
  JobsiteSearch = 'JobsiteSearch',
  MonthCalendar = 'MonthCalendar',
  RegisterBookingSummary = 'RegisterBookingSummary',
  TimesheetJobsiteSummary = 'TimesheetJobsiteSummary',
  WeekCalendar = 'WeekCalendar'
}

export enum AInesToolCallType {
  ActivatePlanningJobsite = 'activatePlanningJobsite',
  AddJobsiteToBrowseList = 'addJobsiteToBrowseList',
  DisablePlanningJobsite = 'disablePlanningJobsite',
  GetActivePlanningJobsites = 'getActivePlanningJobsites',
  GetDays = 'getDays',
  GetDemandBuckets = 'getDemandBuckets',
  GetJobsiteBrowseList = 'getJobsiteBrowseList',
  GetPlanningStatus = 'getPlanningStatus',
  GetUnsignedTimesheets = 'getUnsignedTimesheets',
  RemoveJobsiteFromBrowseList = 'removeJobsiteFromBrowseList',
  SetAvailability = 'setAvailability',
  SetBookingTarget = 'setBookingTarget',
  SetCandidateInterestedIn = 'setCandidateInterestedIn',
  SetJobsiteList = 'setJobsiteList',
  SetSickLeave = 'setSickLeave',
  SetTimeoff = 'setTimeoff',
  SetVacation = 'setVacation',
  SignalBookingInterest = 'signalBookingInterest'
}

export type Absence = {
  __typename?: 'Absence';
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['KapitelDateImmutable']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  type: AbsenceType;
  worktimeValuation?: Maybe<Scalars['Float']['output']>;
};

export type AbsenceTemplate = {
  __typename?: 'AbsenceTemplate';
  comment?: Maybe<Scalars['String']['output']>;
  dayOfWeekISO: Scalars['Int']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  type: AbsenceType;
};

export enum AbsenceType {
  ExternalBooking = 'EXTERNAL_BOOKING',
  Sickness = 'SICKNESS',
  Timeoff = 'TIMEOFF',
  Vacation = 'VACATION'
}

export type AbsenceWorktimeValuationDefault = {
  __typename?: 'AbsenceWorktimeValuationDefault';
  date: Scalars['KapitelDateImmutable']['output'];
  employee: Employee;
  type: AbsenceType;
  value: Scalars['Float']['output'];
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AutopilotJobsite = {
  __typename?: 'AutopilotJobsite';
  jobsite: Jobsite;
  source: AutopilotJobsiteSource;
};

export enum AutopilotJobsiteSource {
  Favorite = 'FAVORITE',
  Manually = 'MANUALLY'
}

export enum AutopilotModus {
  DoNothing = 'DO_NOTHING',
  RemoveAll = 'REMOVE_ALL',
  ReviewAndAdd = 'REVIEW_AND_ADD'
}

export enum AutopilotPlayState {
  Paused = 'PAUSED',
  Running = 'RUNNING',
  Scrapped = 'SCRAPPED'
}

export type AutopilotPreference = {
  __typename?: 'AutopilotPreference';
  autopilotJobsites: Array<AutopilotJobsite>;
  bookingTarget?: Maybe<Scalars['Int']['output']>;
  month: Scalars['KapitelDateImmutable']['output'];
  playState: AutopilotPlayState;
  worktimeMode?: Maybe<WorktimeMode>;
};

export type AutopilotStatus = {
  __typename?: 'AutopilotStatus';
  absenceHours: Scalars['Float']['output'];
  autopilotPreferences: AutopilotPreference;
  averageShiftDuration: Scalars['Float']['output'];
  booked: Scalars['Int']['output'];
  bookedWorkingHours: Scalars['Float']['output'];
  bookingHoursTargetReached: Scalars['Boolean']['output'];
  bookingTarget: Scalars['Int']['output'];
  bookingTargetHours: Scalars['Float']['output'];
  contractHours: Scalars['Float']['output'];
  employee: Employee;
  missingBookingHours: Scalars['Float']['output'];
  missingBookings: Scalars['Int']['output'];
  modus: AutopilotModus;
  month: Scalars['KapitelDateImmutable']['output'];
  offerStrategy: OfferStrategy;
  worktimeMode: WorktimeMode;
  worktimeModeModifierHours: Scalars['Float']['output'];
};

export type Availability = {
  __typename?: 'Availability';
  amenId?: Maybe<Scalars['Int']['output']>;
  constraints: Array<AvailabilityTimeConstraint>;
  date: Scalars['KapitelDateImmutable']['output'];
  differsFromTemplate?: Maybe<Scalars['Boolean']['output']>;
  effectiveIntervals?: Maybe<Array<AvailabilityEffectiveInterval>>;
  effectiveJobsites?: Maybe<Array<Jobsite>>;
  excludedJobsites?: Maybe<Array<Jobsite>>;
  id?: Maybe<Scalars['Int']['output']>;
  shifts: Array<AvailabilityShift>;
};

export type AvailabilityEffectiveInterval = {
  __typename?: 'AvailabilityEffectiveInterval';
  begin: Scalars['DateTime']['output'];
  until: Scalars['DateTime']['output'];
};

export type AvailabilityEffectiveIntervalInput = {
  begin: Scalars['DateTime']['input'];
  until: Scalars['DateTime']['input'];
};

export type AvailabilityPlanningDates = {
  __typename?: 'AvailabilityPlanningDates';
  daysBeforeAutoPlan: Scalars['Int']['output'];
  defaulted: Scalars['Boolean']['output'];
  firstUnplannedDay: Scalars['KapitelDateImmutable']['output'];
  planningThreshold: Scalars['KapitelDateImmutable']['output'];
};

export type AvailabilityPlanningDatesInput = {
  daysBeforeAutoPlan: Scalars['Int']['input'];
  planningThreshold: Scalars['KapitelDateImmutable']['input'];
};

export type AvailabilityShift = {
  __typename?: 'AvailabilityShift';
  begin: Scalars['DateTime']['output'];
  type: ShiftType;
  until: Scalars['DateTime']['output'];
};

export type AvailabilityShiftDefault = {
  __typename?: 'AvailabilityShiftDefault';
  begin: Scalars['KapitelTimeImmutable']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  type: ShiftType;
  until: Scalars['KapitelTimeImmutable']['output'];
};

export type AvailabilityShiftDefaultInput = {
  begin: Scalars['KapitelTimeImmutable']['input'];
  type: ShiftType;
  until: Scalars['KapitelTimeImmutable']['input'];
};

export type AvailabilityShiftInput = {
  begin: Scalars['DateTime']['input'];
  type: ShiftType;
  until: Scalars['DateTime']['input'];
};

export type AvailabilityTemplate = {
  __typename?: 'AvailabilityTemplate';
  constraints: Array<AvailabilityTemplateTimeConstraint>;
  dayOfWeekISO: Scalars['Int']['output'];
  shifts: Array<AvailabilityTemplateShift>;
};

export type AvailabilityTemplateShift = {
  __typename?: 'AvailabilityTemplateShift';
  type: ShiftType;
};

export type AvailabilityTemplateShiftInput = {
  type: ShiftType;
};

export type AvailabilityTemplateTimeConstraint = {
  __typename?: 'AvailabilityTemplateTimeConstraint';
  begin: Scalars['KapitelTimeImmutable']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  until: Scalars['KapitelTimeImmutable']['output'];
};

export type AvailabilityTemplateTimeConstraintInput = {
  begin: Scalars['KapitelTimeImmutable']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  until: Scalars['KapitelTimeImmutable']['input'];
};

export type AvailabilityTimeConstraint = {
  __typename?: 'AvailabilityTimeConstraint';
  begin: Scalars['DateTime']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  until: Scalars['DateTime']['output'];
};

export type AvailabilityTimeConstraintInput = {
  begin: Scalars['DateTime']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  until: Scalars['DateTime']['input'];
};

export type Booking = {
  __typename?: 'Booking';
  amenId?: Maybe<Scalars['Int']['output']>;
  begin: Scalars['DateTime']['output'];
  bookedBy?: Maybe<Contact>;
  date: Scalars['KapitelDateImmutable']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  jobsite: Jobsite;
  qualification: Qualification;
  shiftInformation: ShiftInformation;
  shiftType: ShiftType;
  until: Scalars['DateTime']['output'];
  workingHours: Scalars['Float']['output'];
};

export type Cancellation = {
  __typename?: 'Cancellation';
  amenId?: Maybe<Scalars['Int']['output']>;
  begin: Scalars['DateTime']['output'];
  date: Scalars['KapitelDateImmutable']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  shiftInformation: ShiftInformation;
  shiftType: ShiftType;
  until: Scalars['DateTime']['output'];
};

export type Candidate = {
  __typename?: 'Candidate';
  contact?: Maybe<Scalars['String']['output']>;
  employeeQualification?: Maybe<EmployeeQualification>;
  favoriteJobsites: Array<Jobsite>;
  id?: Maybe<Scalars['Int']['output']>;
  interestedIn?: Maybe<JobsiteType>;
  interestedInMissingJobsiteType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  promoted: Scalars['Boolean']['output'];
  user: User;
};

export type Client = {
  __typename?: 'Client';
  abbreviation?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  icon?: Maybe<Scalars['String']['output']>;
  imageURL?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Contact = {
  __typename?: 'Contact';
  mail?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Day = {
  __typename?: 'Day';
  absence?: Maybe<Absence>;
  availability?: Maybe<Availability>;
  booking?: Maybe<Booking>;
  date: Scalars['KapitelDateImmutable']['output'];
  demands: Array<Demand>;
  wishDemands: Array<Demand>;
};

export type Demand = {
  __typename?: 'Demand';
  begin: Scalars['DateTime']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  isWishDemand?: Maybe<Scalars['Boolean']['output']>;
  jobsite?: Maybe<Jobsite>;
  shiftInformation: ShiftInformation;
  shiftType: ShiftType;
  until: Scalars['DateTime']['output'];
};

export type DeviceProfile = {
  __typename?: 'DeviceProfile';
  firebaseCloudMessagingRegistrationToken?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type DeviceProfileInput = {
  firebaseCloudMessagingRegistrationToken?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
};

export type Employee = {
  __typename?: 'Employee';
  aZK?: Maybe<EmployeeAzk>;
  contract?: Maybe<EmployeeContract>;
  contracts: Array<EmployeeContract>;
  firstContract: EmployeeContract;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  offerableQualifications: Array<Qualification>;
  targetAZK?: Maybe<EmployeeTargetAzk>;
  user: User;
};


export type EmployeeContractArgs = {
  month: Scalars['KapitelDateImmutable']['input'];
};

export type EmployeeAzk = {
  __typename?: 'EmployeeAZK';
  employee: Employee;
  validMonth: Scalars['KapitelDateImmutable']['output'];
  value?: Maybe<Scalars['Float']['output']>;
};

export type EmployeeCalendarDate = {
  __typename?: 'EmployeeCalendarDate';
  date: Scalars['KapitelDateImmutable']['output'];
  employee: Employee;
};

export type EmployeeCalendarDateRange = EmployeeCalendarDateRangeInterface & {
  __typename?: 'EmployeeCalendarDateRange';
  employee: Employee;
  max: Scalars['KapitelDateImmutable']['output'];
  min: Scalars['KapitelDateImmutable']['output'];
};

export type EmployeeCalendarDateRangeInterface = {
  employee: Employee;
  max: Scalars['KapitelDateImmutable']['output'];
  min: Scalars['KapitelDateImmutable']['output'];
};

export type EmployeeCalendarMonth = EmployeeCalendarDateRangeInterface & {
  __typename?: 'EmployeeCalendarMonth';
  employee: Employee;
  max: Scalars['KapitelDateImmutable']['output'];
  min: Scalars['KapitelDateImmutable']['output'];
  month: Scalars['KapitelDateImmutable']['output'];
};

export type EmployeeContract = {
  __typename?: 'EmployeeContract';
  maximumMonthlyOvertimeHours?: Maybe<Scalars['Float']['output']>;
  monthlyHours: Scalars['Float']['output'];
  validFrom: Scalars['KapitelDateImmutable']['output'];
  validTo?: Maybe<Scalars['KapitelDateImmutable']['output']>;
};

export type EmployeeQualification = {
  __typename?: 'EmployeeQualification';
  experiences?: Maybe<Array<Scalars['String']['output']>>;
  qualification?: Maybe<Scalars['String']['output']>;
  specialisation?: Maybe<Scalars['String']['output']>;
};

export type EmployeeTargetAzk = {
  __typename?: 'EmployeeTargetAZK';
  employee: Employee;
  value?: Maybe<Scalars['Float']['output']>;
};

export type EmployeeTargetAzkInput = {
  value: Scalars['Float']['input'];
};

export type Fachbereich = {
  __typename?: 'Fachbereich';
  abbreviation?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type House = {
  __typename?: 'House';
  abbreviation?: Maybe<Scalars['String']['output']>;
  address?: Maybe<Address>;
  client?: Maybe<Client>;
  imageURL?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Jobsite = {
  __typename?: 'Jobsite';
  abbreviation?: Maybe<Scalars['String']['output']>;
  about?: Maybe<Scalars['String']['output']>;
  additionalAmenImportantAbbreviations?: Maybe<Array<Scalars['String']['output']>>;
  additionalAmenImportantLabels?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<Address>;
  amenDeactivated?: Maybe<Scalars['Boolean']['output']>;
  bedCount?: Maybe<Scalars['Int']['output']>;
  bedTypes?: Maybe<Scalars['String']['output']>;
  careRatio?: Maybe<Scalars['String']['output']>;
  client: Client;
  contact?: Maybe<Contact>;
  enrichedAbout?: Maybe<Scalars['String']['output']>;
  equipment?: Maybe<Array<Scalars['String']['output']>>;
  fachbereiche: Array<Fachbereich>;
  house?: Maybe<House>;
  id: Scalars['Int']['output'];
  isAlternative?: Maybe<Scalars['Boolean']['output']>;
  isBooked?: Maybe<Scalars['Boolean']['output']>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  jobsiteType: Array<JobsiteType>;
  manuallyCurated?: Maybe<Scalars['Boolean']['output']>;
  medicalAbout?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  qualificationAbout?: Maybe<Scalars['String']['output']>;
  requirements?: Maybe<Scalars['String']['output']>;
  retrieved?: Maybe<Scalars['Boolean']['output']>;
  shiftTimes?: Maybe<Array<Scalars['String']['output']>>;
};

export type JobsiteInput = {
  id: Scalars['Int']['input'];
};

export type JobsiteSearchResult = {
  __typename?: 'JobsiteSearchResult';
  isLastItem: Scalars['Boolean']['output'];
  jobsite: Jobsite;
};

export type JobsiteType = {
  __typename?: 'JobsiteType';
  name?: Maybe<Scalars['String']['output']>;
};

export type KapitelDateIntervalInput = {
  max: Scalars['KapitelDateImmutable']['input'];
  min: Scalars['KapitelDateImmutable']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addBlacklistJobsite: Array<Jobsite>;
  addFavoriteJobsite: Array<Jobsite>;
  addJobsiteToAutopilot: Array<AutopilotJobsite>;
  addSecondChoiceJobsites: Array<Jobsite>;
  addWishDemand: Scalars['Boolean']['output'];
  availabilityFromDemand: Scalars['Boolean']['output'];
  /** create a new absence for current user */
  createAbsenceTemplate: AbsenceTemplate;
  /** create a new default availability shift for current user */
  createAvailabilityShiftDefault: AvailabilityShiftDefault;
  /** delete an users absence by id */
  deleteAvailabilityShiftDefault: Scalars['Boolean']['output'];
  login: SymfonyUserInterface;
  logout: Scalars['Boolean']['output'];
  /** delete an users absence by id */
  removeAbsence: Scalars['Boolean']['output'];
  /** delete an users absence by id */
  removeAbsenceTemplate: Scalars['Boolean']['output'];
  /** delete an users availability by date */
  removeAvailability: Scalars['Boolean']['output'];
  /** delete an users availability template by id */
  removeAvailabilityTemplate: Scalars['Boolean']['output'];
  removeBlacklistJobsite: Array<Jobsite>;
  removeFavoriteJobsite: Array<Jobsite>;
  removeJobsiteFromAutopilot: Array<AutopilotJobsite>;
  removeSecondChoiceJobsites: Array<Jobsite>;
  removeWishDemand: Scalars['Boolean']['output'];
  respondToAInesRequest: AInesRequestReaction;
  /** delete an users availability by date */
  saveTimesheet: Scalars['Boolean']['output'];
  /** create a new absence for current user */
  setAbsence: Absence;
  /** create a new availability for current user */
  setAvailability: Availability;
  /** create a new availability for current user */
  setAvailabilityAbsence: Day;
  /** advance planning date */
  setAvailabilityPlanningDate: AvailabilityPlanningDates;
  /** update planning dates (advance planning date & update daysBeforeAutoPlan) */
  setAvailabilityPlanningDates: AvailabilityPlanningDates;
  /** create a new availability template for current user */
  setAvailabilityTemplate: AvailabilityTemplate;
  setDeviceProfile?: Maybe<DeviceProfile>;
  setRegisterBookings: Array<Booking>;
  updateAutopilotPreference: AutopilotPreference;
  updateCandidate: Candidate;
  updateSecondChoiceJobsites: Array<Jobsite>;
  updateTargetAZK: EmployeeTargetAzk;
};


export type MutationAddBlacklistJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationAddFavoriteJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationAddJobsiteToAutopilotArgs = {
  jobsiteId: Scalars['Int']['input'];
  month: Scalars['KapitelDateImmutable']['input'];
};


export type MutationAddSecondChoiceJobsitesArgs = {
  jobsiteId: Scalars['Int']['input'];
};


export type MutationAddWishDemandArgs = {
  demandId: Scalars['Int']['input'];
};


export type MutationAvailabilityFromDemandArgs = {
  demandId: Scalars['Int']['input'];
};


export type MutationCreateAbsenceTemplateArgs = {
  dayOfWeekISO: Scalars['Int']['input'];
  type: AbsenceType;
};


export type MutationCreateAvailabilityShiftDefaultArgs = {
  shiftDefault: AvailabilityShiftDefaultInput;
};


export type MutationDeleteAvailabilityShiftDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};


export type MutationRemoveAbsenceArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
};


export type MutationRemoveAbsenceTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveAvailabilityArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
};


export type MutationRemoveAvailabilityTemplateArgs = {
  dayOfWeekISO: Scalars['Int']['input'];
};


export type MutationRemoveBlacklistJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveFavoriteJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRemoveJobsiteFromAutopilotArgs = {
  jobsiteId: Scalars['Int']['input'];
  month: Scalars['KapitelDateImmutable']['input'];
};


export type MutationRemoveSecondChoiceJobsitesArgs = {
  jobsiteId: Scalars['Int']['input'];
};


export type MutationRemoveWishDemandArgs = {
  demandId: Scalars['Int']['input'];
};


export type MutationRespondToAInesRequestArgs = {
  reactionType: AInesRequestReactionType;
  requestScope: Scalars['String']['input'];
  requestSubject: AInesRequestSubject;
};


export type MutationSaveTimesheetArgs = {
  begin: Scalars['DateTime']['input'];
  bookingId: Scalars['Int']['input'];
  breakBegin?: InputMaybe<Scalars['DateTime']['input']>;
  breakUntil?: InputMaybe<Scalars['DateTime']['input']>;
  signateur: Scalars['String']['input'];
  signateurEmail: Scalars['String']['input'];
  signature: Scalars['String']['input'];
  until: Scalars['DateTime']['input'];
};


export type MutationSetAbsenceArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  date: Scalars['KapitelDateImmutable']['input'];
  type: AbsenceType;
  worktimeValuation?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationSetAvailabilityArgs = {
  constraints?: Array<AvailabilityTimeConstraintInput>;
  date: Scalars['KapitelDateImmutable']['input'];
  excludedJobsiteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  shifts?: Array<AvailabilityShiftInput>;
};


export type MutationSetAvailabilityAbsenceArgs = {
  absenceComment?: InputMaybe<Scalars['String']['input']>;
  absenceType?: InputMaybe<AbsenceType>;
  absenceWorktimeValuation?: InputMaybe<Scalars['Float']['input']>;
  availabilityConstraints?: InputMaybe<Array<AvailabilityTimeConstraintInput>>;
  availabilityExcludedJobsiteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  availabilityShifts?: InputMaybe<Array<AvailabilityShiftInput>>;
  date: Scalars['KapitelDateImmutable']['input'];
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetAvailabilityPlanningDateArgs = {
  planningThreshold: Scalars['KapitelDateImmutable']['input'];
};


export type MutationSetAvailabilityPlanningDatesArgs = {
  newAvailabilityPlanningDates: AvailabilityPlanningDatesInput;
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationSetAvailabilityTemplateArgs = {
  constraints: Array<AvailabilityTemplateTimeConstraintInput>;
  dayOfWeekISO: Scalars['Int']['input'];
  shifts: Array<AvailabilityTemplateShiftInput>;
};


export type MutationSetDeviceProfileArgs = {
  newDeviceProfile: DeviceProfileInput;
  oldFirebaseCloudMessagingRegistrationToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetRegisterBookingsArgs = {
  bookingInputs: Array<RegisterBookingInput>;
};


export type MutationUpdateAutopilotPreferenceArgs = {
  bookingTarget?: InputMaybe<Scalars['Int']['input']>;
  month: Scalars['KapitelDateImmutable']['input'];
  playState?: InputMaybe<AutopilotPlayState>;
  worktimeMode?: InputMaybe<WorktimeMode>;
};


export type MutationUpdateCandidateArgs = {
  contact: Scalars['String']['input'];
  experiences?: InputMaybe<Array<Scalars['String']['input']>>;
  interestedIn?: InputMaybe<Scalars['String']['input']>;
  interestedInMissingJobsiteType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  promoted?: InputMaybe<Scalars['Boolean']['input']>;
  qualification?: InputMaybe<AInesEmployeeQualificationType>;
  specialization?: InputMaybe<AInesEmployeeQualificationSpecializationType>;
};


export type MutationUpdateSecondChoiceJobsitesArgs = {
  orderedJobsites: Array<Scalars['Int']['input']>;
};


export type MutationUpdateTargetAzkArgs = {
  newTargetAZK: EmployeeTargetAzkInput;
};

export enum OfferStrategy {
  MinimalDiversity = 'MINIMAL_DIVERSITY',
  Unrestricted = 'UNRESTRICTED'
}

export enum PlanningMode {
  AvailabilityPlanning = 'AVAILABILITY_PLANNING',
  BookingPlanning = 'BOOKING_PLANNING',
  Uncontracted = 'UNCONTRACTED'
}

export type PlanningStatus = {
  __typename?: 'PlanningStatus';
  autopilotStatus?: Maybe<AutopilotStatus>;
  availabilities: Scalars['Int']['output'];
  availabilityShifts: Array<ShiftType>;
  bookedJobsites: Array<Jobsite>;
  bookingCompleted: Scalars['Boolean']['output'];
  bookings: Scalars['Int']['output'];
  contractHours: Scalars['Float']['output'];
  employee: Employee;
  month: Scalars['KapitelDateImmutable']['output'];
  planningMode: PlanningMode;
  vacations: Scalars['Int']['output'];
};

export type PlanningTemplate = {
  __typename?: 'PlanningTemplate';
  availabilityShiftSuggestion: Array<PlanningTemplateShiftSuggestion>;
  bookingTargetMax?: Maybe<Scalars['Int']['output']>;
  bookingTargetMin: Scalars['Int']['output'];
  bookingTargetSpotOn: Scalars['Int']['output'];
  bookingTargetSuggestion: Scalars['Int']['output'];
};

export type PlanningTemplateShiftSuggestion = {
  __typename?: 'PlanningTemplateShiftSuggestion';
  dow: Scalars['Int']['output'];
  shifts: Array<ShiftType>;
};

export type PreCrime = {
  __typename?: 'PreCrime';
  dates: Array<Scalars['KapitelDateImmutable']['output']>;
  employee: Employee;
  jobsites: Array<Jobsite>;
  value: Scalars['Float']['output'];
};

export enum PreCrimeAvailabilityFilterType {
  Inverted = 'INVERTED',
  Normal = 'NORMAL',
  Off = 'OFF'
}

export type Qualification = {
  __typename?: 'Qualification';
  id?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** used only to have AInesEmployeeQualificationExperiencesType enum in FE schema */
  AInesEmployeeQualificationExperiencesTypeC: Array<AInesEmployeeQualificationExperiencesType>;
  absence?: Maybe<Absence>;
  absenceTemplate?: Maybe<AbsenceTemplate>;
  absenceTemplates: Array<AbsenceTemplate>;
  absenceWorktimeValuationDefault: AbsenceWorktimeValuationDefault;
  absences: Array<Absence>;
  /** used only to have AInesRichResponseType enum in FE schema */
  assistantTypesC: Array<AInesAssistantType>;
  /** used only to have AInesRichResponseType enum in FE schema */
  assistantTypesMapping: Array<AInesAssistantFeatureMapping>;
  autopilotPreference?: Maybe<AutopilotPreference>;
  autopilotStatus?: Maybe<AutopilotStatus>;
  availabilities: Array<Availability>;
  availability?: Maybe<Availability>;
  availabilityPlanningDates: AvailabilityPlanningDates;
  availabilityShiftDefault: AvailabilityShiftDefault;
  availabilityShiftDefaults: Array<AvailabilityShiftDefault>;
  availabilityTemplate?: Maybe<AvailabilityTemplate>;
  availabilityTemplates: Array<AvailabilityTemplate>;
  blacklistJobsites: Array<Jobsite>;
  bookedJobsites: Array<Jobsite>;
  booking: Booking;
  bookings: Array<Booking>;
  cancellation: Cancellation;
  cancellations: Array<Cancellation>;
  candidate: Candidate;
  createCandidate: Scalars['String']['output'];
  createPromotedCandidate: Scalars['String']['output'];
  currentAInesRequests: Array<AInesRequest>;
  currentAutopilotStatus: Array<AutopilotStatus>;
  day: Day;
  days: Array<Day>;
  demand: Array<Demand>;
  demandByIds: Array<Demand>;
  deviceProfiles: Array<DeviceProfile>;
  employee: Employee;
  employees: Array<Employee>;
  favoriteJobsite: Jobsite;
  favoriteJobsites: Array<Jobsite>;
  generateTimesheetToDos: Array<TimesheetToDo>;
  globalCoreTimes: Array<ShiftTimes>;
  globalHullTimes: Array<ShiftTimes>;
  /** used only to have AInesInterestedInJobsiteTypes enum in FE schema */
  interestedInJobsiteTypesC: Array<AInesInterestedInJobsiteTypes>;
  jobsite?: Maybe<Jobsite>;
  /** used only to have AInesRichResponseType enum in FE schema */
  jobsiteListTypesC: Array<AInesJobsiteListType>;
  jobsiteShiftTimes: Array<ShiftTimes>;
  jobsites: Array<Jobsite>;
  jobsitesByQuery: Array<JobsiteSearchResult>;
  me?: Maybe<SymfonyUserInterface>;
  openaiApiKey: Scalars['String']['output'];
  planningStatus: PlanningStatus;
  planningTemplate: PlanningTemplate;
  precrime: PreCrime;
  registerBookingDefaults: RegisterBookingDefault;
  resolvedJobsites: Array<Jobsite>;
  /** used only to have AInesRichResponseType enum in FE schema */
  richResponseTypes: Array<AInesRichResponseType>;
  secondChoiceJobsites: Array<Jobsite>;
  targetAZK: EmployeeTargetAzk;
  /** used only to have AInesToolCallType enum in FE schema */
  toolCallTypesC: Array<AInesToolCallType>;
  upcomingBooking?: Maybe<Booking>;
  wishDemand: Array<Demand>;
};


export type QueryAbsenceArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
};


export type QueryAbsenceTemplateArgs = {
  dayOfWeekISO: Scalars['Int']['input'];
};


export type QueryAbsenceWorktimeValuationDefaultArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
  type: AbsenceType;
};


export type QueryAbsencesArgs = {
  betweenDates?: InputMaybe<KapitelDateIntervalInput>;
};


export type QueryAutopilotPreferenceArgs = {
  month: Scalars['KapitelDateImmutable']['input'];
};


export type QueryAutopilotStatusArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  month: Scalars['KapitelDateImmutable']['input'];
};


export type QueryAvailabilitiesArgs = {
  betweenDates: KapitelDateIntervalInput;
};


export type QueryAvailabilityArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
};


export type QueryAvailabilityShiftDefaultArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<ShiftType>;
};


export type QueryAvailabilityTemplateArgs = {
  dayOfWeekISO: Scalars['Int']['input'];
};


export type QueryBookingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBookingsArgs = {
  betweenDates?: InputMaybe<KapitelDateIntervalInput>;
  employees?: Array<Scalars['ID']['input']>;
  fromToTimestamp?: InputMaybe<TimestampIntervalInput>;
  ids?: Array<Scalars['ID']['input']>;
  onDate?: InputMaybe<Scalars['DateTime']['input']>;
  onTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCancellationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCancellationsArgs = {
  betweenDates?: InputMaybe<KapitelDateIntervalInput>;
  fromToTimestamp?: InputMaybe<TimestampIntervalInput>;
  ids?: Array<Scalars['ID']['input']>;
  onDate?: InputMaybe<Scalars['DateTime']['input']>;
  onTimestamp?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCreateCandidateArgs = {
  contact?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  qualification?: InputMaybe<Scalars['String']['input']>;
  specialisation?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCreatePromotedCandidateArgs = {
  contact?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  qualification: Scalars['String']['input'];
  specialisation?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDayArgs = {
  date: Scalars['KapitelDateImmutable']['input'];
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDaysArgs = {
  betweenDates: KapitelDateIntervalInput;
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDemandArgs = {
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDemandByIdsArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type QueryFavoriteJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type QueryJobsiteArgs = {
  id: Scalars['Int']['input'];
};


export type QueryJobsiteShiftTimesArgs = {
  jobsite: JobsiteInput;
};


export type QueryJobsitesArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type QueryJobsitesByQueryArgs = {
  excludeJobsiteIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlanningStatusArgs = {
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  month: Scalars['KapitelDateImmutable']['input'];
  useStuffing?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPlanningTemplateArgs = {
  month: Scalars['KapitelDateImmutable']['input'];
};


export type QueryPrecrimeArgs = {
  dates: Array<Scalars['KapitelDateImmutable']['input']>;
  filterByAvailabilities?: InputMaybe<PreCrimeAvailabilityFilterType>;
  jobsites: Array<JobsiteInput>;
};


export type QueryResolvedJobsitesArgs = {
  jobsiteQuery: Scalars['String']['input'];
};


export type QueryWishDemandArgs = {
  jobsite_ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RegisterBookingDefault = {
  __typename?: 'RegisterBookingDefault';
  jobsite?: Maybe<Jobsite>;
  qualification?: Maybe<Qualification>;
  shiftTimes: Array<ShiftTimes>;
};

export type RegisterBookingInput = {
  begin: Scalars['DateTime']['input'];
  jobsiteId: Scalars['Int']['input'];
  qualificationId: Scalars['Int']['input'];
  until: Scalars['DateTime']['input'];
};

export type ResponseMessage = {
  __typename?: 'ResponseMessage';
  richResponses?: Maybe<RichResponses>;
  text?: Maybe<Scalars['String']['output']>;
};

export type RichResponse = {
  __typename?: 'RichResponse';
  reference?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type RichResponses = {
  __typename?: 'RichResponses';
  responses: Array<RichResponse>;
};

export type ShiftInformation = {
  __typename?: 'ShiftInformation';
  comment?: Maybe<Scalars['String']['output']>;
  requestedBy?: Maybe<Contact>;
};

export type ShiftTimes = {
  __typename?: 'ShiftTimes';
  begin: Scalars['KapitelTimeImmutable']['output'];
  type?: Maybe<ShiftType>;
  until: Scalars['KapitelTimeImmutable']['output'];
};

export enum ShiftType {
  Fd = 'FD',
  Nd = 'ND',
  Sd = 'SD'
}

export type SymfonyUserImpl = SymfonyUserInterface & {
  __typename?: 'SymfonyUserImpl';
  roles: Array<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type SymfonyUserInterface = {
  roles: Array<Scalars['String']['output']>;
  userName: Scalars['String']['output'];
};

export type Timesheet = {
  __typename?: 'Timesheet';
  begin: Scalars['DateTime']['output'];
  breakBegin?: Maybe<Scalars['DateTime']['output']>;
  breakUntil?: Maybe<Scalars['DateTime']['output']>;
  signateur?: Maybe<Scalars['String']['output']>;
  signateurEmail?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  until: Scalars['DateTime']['output'];
};

export type TimesheetToDo = {
  __typename?: 'TimesheetToDo';
  booking: Booking;
  employee: Employee;
  timesheet: Timesheet;
};

export type TimestampIntervalInput = {
  min: Scalars['DateTime']['input'];
  supremum: Scalars['DateTime']['input'];
};

export type User = SymfonyUserInterface & {
  __typename?: 'User';
  id?: Maybe<Scalars['Int']['output']>;
  roles: Array<Scalars['String']['output']>;
  /** A visual identifier that represents this user. */
  userIdentifier: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export enum WorktimeMode {
  Less = 'LESS',
  More = 'MORE',
  SpotOn = 'SPOT_ON'
}

<script setup lang="ts">
import { Jobsite } from '@/graphql/generated/graphql';
import { computed, ref, watch } from "vue";
import Modal from "@/components/Modal.vue";
import { IonButton, IonButtons, IonIcon } from "@ionic/vue";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import { trashOutline } from "ionicons/icons";
import { removeFavoriteJobsite, getImageForJobsite } from '@/helper/jobsite';
import { useCandidateStore } from '@/store/candidate';

const isOpen = defineModel({type: Boolean})
const candidateStore = useCandidateStore()
const candidate = computed(() => candidateStore.storedCandidate)
const favoriteJobsites = computed(() => candidate.value?.favoriteJobsites)

const removeJobsite = (jobsite: Jobsite) => {
    removeFavoriteJobsite(jobsite).then(candidateStore.reloadCandidate)
}
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Gemerkte Einsatzorte"
        >

        <RichResponseListItem
            v-for="jobsite in favoriteJobsites"
            :title="jobsite.name"
            iconType="image"
            :icon="getImageForJobsite(jobsite)"
            >
            <template v-slot:end>
                <ion-buttons>
                    <ion-button
                        color="secondary"
                        fill="clear"
                        @click="removeJobsite(jobsite)"
                        >
                        <ion-icon
                            slot="icon-only"
                            :src="trashOutline"
                            />
                    </ion-button>
                </ion-buttons>
            </template>
        </RichResponseListItem>
    </Modal>
</template>

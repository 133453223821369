<script setup>
import { IonModal, IonToolbar, IonHeader, IonTitle, IonButtons, IonButton, IonIcon } from '@ionic/vue'
import { ref, watch, onMounted, onUnmounted } from 'vue'

const props = defineProps({
    modelValue: Boolean,
    title: String,
    fullscreen: Boolean,
    toolbar: {
        type: Boolean,
        default: (props) => props.fullscreen ? false : true,
        required: false,
    },
    stacking: {
        type: Boolean,
        default: false,
        required: false,
    },
})

const jonModal = ref()

const emit = defineEmits(['update:modelValue', "view:didPresent", "view:didDismiss"])

const onDidPresent = () => {
    emit("view:didPresent")
}

const isOpen = ref(props.modelValue)
const keyboardEventsAreRegistered = ref(true)
const keyboardHeight = ref(0)
const presentingElement = ref(undefined)

const onDidDismiss = () => {
    emit("view:didDismiss")
}

const onWillDismiss = () => {
    dismiss()
}

const determinePresentingElement = () => {
    if (!props.stacking) {
        return
    }

    const $appContainer = document.querySelector('ion-app')
    const $page = $appContainer && $appContainer.querySelector('div.ion-page')

    presentingElement.value = $page
}

const dismiss = () => {
    return new Promise(
        (resolve) => {
            if (jonModal.value) {
                jonModal.value.$el.onDidDismiss().then(resolve)
            }
            
            isOpen.value = false
            emit('update:modelValue', false)
        }
    )
}

const onKeyboardDidShow = ev => setKeyboardIsVisible(true, ev.detail.keyboardHeight)
const onKeyboardWillShow = () => { setTimeout(() => { setKeyboardIsVisible(true) }, 150) }
const onKeyboardDidHide = () => setKeyboardIsVisible(false)

const setKeyboardIsVisible = (value, height) => {
    keyboardHeight.value = height || 0

    const $el = jonModal.value?.$el
    
    if (!$el) {
        return
    }

    if (value) {
        $el.classList.add('keyboard-visible')
    }
    else {
        $el.classList.remove('keyboard-visible')
    }
}

const registerKeyboardEvents = () => {
    if (keyboardEventsAreRegistered.value) {
        return
    }

    keyboardEventsAreRegistered.value = true
    window.addEventListener('ionKeyboardDidShow', onKeyboardDidShow)
    window.addEventListener('ionKeyboardDidHide', onKeyboardDidHide)
    window.addEventListener("keyboardWillShow", onKeyboardWillShow);
}

const unregisterKeyboardEvents = () => {
    onKeyboardDidHide()
    
    keyboardEventsAreRegistered.value = false
    window.removeEventListener('ionKeyboardDidShow', onKeyboardDidShow)
    window.removeEventListener('ionKeyboardDidHide', onKeyboardDidHide)
    window.removeEventListener("keyboardWillShow", onKeyboardWillShow);
}

watch(() => props.modelValue, value => {
    isOpen.value = value
}, { immediate: true })

watch(isOpen, value => {
    if (value) {
        registerKeyboardEvents()
    }
    else {
        unregisterKeyboardEvents()
    }
}, { immediate: true })

defineExpose({
    dismiss
})

onMounted(() => {
    determinePresentingElement()
    registerKeyboardEvents()
})

onUnmounted(() => {
    unregisterKeyboardEvents()
})

const onTouchMove = (event) => {
    const scrollTop = event.currentTarget.scrollTop

    if (scrollTop > 0) {
        event.stopPropagation()
    }   
}

</script>

<template>
    <ion-modal
        aria-modal="true"
        class="modal"
        :class="{'open': isOpen, 'fullscreen': fullscreen, 'stacking': stacking && !!presentingElement}"
        :style="{'--keyboard-height': keyboardHeight + 'px'}"
        :is-open="isOpen"
        :initial-breakpoint="1"
        :breakpoints="[0, 1]"
        v-on:willDismiss="onWillDismiss"
        v-on:didDismiss="onDidDismiss"
        v-on:didPresent="onDidPresent"
        ref="jonModal"
        :presenting-element="presentingElement"
    >
        <ion-header :class="{'safe-area-top': fullscreen}">
            <ion-toolbar v-if="toolbar">
                <ion-title v-if="title">
                    {{ title }}
                </ion-title>
                <ion-buttons slot="end">
                    <ion-button
                        @click="dismiss"
                        class="button-close"
                        shape="round"
                        fill="solid"
                        color="light"
                    >
                        <ion-icon
                            color="secondary"
                            size="small"
                            src="/icons/x.svg"
                            slot="icon-only"
                        />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <div
            class="body"
            @touchmove="onTouchMove"
        >
            <slot :dismiss="dismiss" />
        </div>
        <div 
            class="footer"
        >
            <slot name="actions" :dismiss="dismiss" />
        </div>

    </ion-modal>
</template>

<style lang="scss">
.modal {
    --max-auto-height: 90vh;
    --height: auto;
    align-items: flex-end;

    ion-toolbar {
        --border-width: 0 !important;

        padding-top: calc(var(--custom-spacing-app-content-padding-vertical) / 1.5) !important;
        padding-left: var(--custom-spacing-app-content-padding-horizontal) !important;
        padding-right: calc(var(--custom-spacing-app-content-padding-horizontal) / 2) !important;

        ion-title {
            text-align: left;
            padding-left: 0;
        }

        ion-button {
            margin-left: 0;
            margin-right: 0;
            width: 2rem;
        }
    }

    &::part(content) {
        max-height: var(--max-auto-height);
        display: flex;
        flex-direction: column;
    }

    &::part(handle) {
        flex: 0 0 auto;
    }

    > .ion-page {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
        overflow-x: hidden;

        > ion-header {
            flex: 0 0 auto;
        }

        > .body {
            width: 100%;
            flex: 1 1 auto;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            position: relative;
        }

        > .footer {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
            margin-bottom: env(safe-area-inset-bottom, 0);
            padding-left: var(--custom-spacing-app-content-padding-horizontal);
            padding-right: var(--custom-spacing-app-content-padding-horizontal);
            padding-bottom: var(--custom-spacing-app-content-padding-vertical);

            > * {
                margin: 0;
                &:not(:first-child) {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .body {
        padding-top: var(--custom-spacing-app-content-padding-vertical);
        padding-left: var(--custom-spacing-app-content-padding-horizontal);
        padding-right: var(--custom-spacing-app-content-padding-horizontal);
        padding-bottom: var(--custom-spacing-app-content-padding-vertical);
    }

    &.no-padding-left .body {
        padding-left: 0;
    }

    &.no-padding-right .body {
        padding-right: 0;
    }

    &.no-padding-top .body {
        padding-top: 0;
    }

    &.no-padding-bottom .body {
        padding-bottom: 0;
    }

    &.fullscreen {
        --height: 100vh;

        &::part(content) {
            max-height: var(--height);
            height: var(--height);
            
        }

        &::part(handle) {
            display: none;
        }

        &.stacking {
            --height: calc(100vh - 3rem);

            ion-header {
                padding-top: 0 !important;
            }
        }
    }

    &.keyboard-visible.open {
        > .ion-page {
            overflow-y: initial;
            display: block;

            > .body {
                display: block;

                ion-content {
                    height: initial;
                    overflow: initial;
                    contain: initial;
                    display: block;

                    &::part(scroll) {
                        position: static;
                        overflow: initial;
                    } 
                }
            }
        }
    }
}
</style>

import {AInesAssistantType, AInesFeatureSet} from "@/graphql/generated/graphql";

import {getAssistentForFeatureSet} from "@/helper/chat/assistantFeatureMapping";
import {AssistantRun} from "@/helper/chat/assistantRun/assistantRun";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";

import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {AssistantRunStatus} from "@/helper/chat/assistantRun/assistantRunStatus";
import {kapitelErrorHandler} from "@/helper/error";
import OpenAI from "openai";


export class AssistantRunChooser extends AssistantRun {

    private choicePromiseResolve: any
    public assistantChoicePromise : Promise<AInesAssistantType|undefined>

    public response : string|object|undefined

    constructor(
        public runConfig: AssistantRunConfig,
        public userMessage: string,
        protected isScriptedContent: boolean
    ) {
        super(runConfig, userMessage, isScriptedContent)

        // prepare choice promise
        this.assistantChoicePromise = new Promise((resolve, reject) => {
            this.choicePromiseResolve = resolve;
            // choicePromiseReject = reject;
        });

        this.submitMessageAndNonStream(userMessage, isScriptedContent)
            .then((result: OpenAI.ChatCompletion) => {
                this.handleResponse(result)
            })
            .catch(() => {
                // state: on content complete progress state from streaming > failed
                this.setState(AssistantRunStatus.FAILED)
                // streamComplete: event
                this.mitt.emit('runFailed')

                const error = 'Run ' + this.getRunId() + ' failed: ' /* + this.getLastError()?.code + ' - ' + this.getLastError()?.message */
                // log to chat console
                consoleErrorChat(error, this)

                // handle error (toast, sentry)
                kapitelErrorHandler(error)
            })

        this.setState(AssistantRunStatus.REQUESTED);
    }

    private handleResponse = async (response: OpenAI.ChatCompletion) => {
        // resolve choice promise
        const content = response.choices[0]?.message.content || ''

        let message
        try {
            message = JSON.parse(content)
        } catch {
            message = undefined
        }
        this.response = message || content || response

        consoleLogChat('%s: response stream completed for userMessage "' + this.userMessage + '" from %O: %O',AInesAssistantType[this.runConfig.assistantType],  this.runConfig, this.response)

        const featureSet = (message && message.chosenFeatureSet && AInesFeatureSet.hasOwnProperty(message.chosenFeatureSet as string)) ? (message.chosenFeatureSet as AInesFeatureSet) : undefined

        const assistant = featureSet ? await getAssistentForFeatureSet(featureSet) : undefined

        this.choicePromiseResolve(assistant)
    }
}

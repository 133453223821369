export const getArrayElementsExcluding = <Type>(arr: Type[], excluding: Type[]) : Type[] => {
    return arr.filter((elem: Type) => !excluding.includes(elem))
}

export const getArrayUnique = <Type>(arr: Type[]) : Type[] => {
    return arr.filter((value, index, array) => array.indexOf(value) === index)
}

export const getRandomArrayElement = <Type>(arr: Type[], excluding?: Type[]) : Type=> {
    if (excluding) {
        arr = getArrayElementsExcluding(arr, excluding)
    }
    const pick = Math.floor(Math.random() * arr.length)
    return arr[pick]
}

export const getRandomArrayElements = <Type>(arr: Type[], n: number) : Type[] => {
    const result : Type[] = []
    for (let x = 0; x < n; x++) {
        const element = getRandomArrayElement(arr, result)
        if (element) {
            result.push(element)
        }
    }
    return result
}

export const getLastArrayElement = <Type>(arr: Type[]) : Type | undefined => {
    return arr.length > 0 ? arr[arr.length - 1] : undefined
}

export const arrayEquals = (arr1: any[], arr2: any[], ignoreOrder = false) : boolean => {
    throw new Error('not implemented')
}

/**
 * [a,a,b] == [a,b,b]
 * [a,b,c] == [a,b,b,c]
 */
export const arrayContentEquals = (arr1: any[], arr2: any[], ignoreDuplicates = false) : boolean => {
    if (ignoreDuplicates) {
        return arr1.every((elem) => arr2.includes(elem)) && arr2.every((elem) => arr1.includes(elem))
    } else {
        throw new Error('not implemented')
    }
}

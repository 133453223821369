<script lang="ts" setup>
import { computed, watch } from 'vue';
import { IonItem, IonLabel } from '@ionic/vue';
import { generateCalendarDate } from '@/helper/calendar/calendarDate';
import { kapitelDateString } from '@/graphql/kapitelTypes';
import Checkbox from '@/components/Checkbox.vue';
import InlineSVG from '@/components/InlineSVG.vue';

const model = defineModel()
const emits = defineEmits(['click', 'selected'])

const props = withDefaults(
    defineProps<{
        title?: string,
        text?: string,
        icon?: string,
        iconType?: 'icon' | 'text' | 'image' | 'date',
        iconColor?: string
        iconBlurred?: boolean
        selectable?: boolean
        selected?: boolean // use when you don't want to use v-model
        image?: string
        button?: Boolean
    }>(),
    {
        iconType: 'text',
        iconColor: 'secondary',
        iconBlurred: false,
        selectable: false,
        selected: false,
    }
)

const iconDate = computed(() => {
    if (props.iconType === 'date') {
        return generateCalendarDate(props.icon as kapitelDateString)
    }
})

const onClick = (event: PointerEvent) => {
    if (!props.button) {
        model.value = !model.value
        emits('selected', model.value)
    }

    emits('click', event)
}

const onClickCheckbox = () => {
    model.value = !model.value
}

watch(() => props.selected, (v) => {
    model.value = v
})
if (props.selected && !model.value) {
    model.value = props.selected
}
</script>

<template>
    <ion-item
        lines="none"
        @click.stop="onClick"
        :button="!!button"
    >
        <div
            class="rich-response-list-item-icon"
            :class="{'blurred': iconBlurred}"
            slot="start"
            v-if="icon"
            :style="'--icon-text-color: var(--ion-color-' + iconColor + '); --icon-text-color-rgb: var(--ion-color-' + iconColor + '-rgb);'"
        >
            <InlineSVG class="icon-icon" v-if="iconType === 'icon'" :src="icon" />
            <span class="icon-text" v-if="iconType === 'text'">{{ icon }}</span>
            <img class="icon-image" v-if="iconType === 'image'" :src="icon" />
            <div
                class="icon-date"
                :class="{'weekend': iconDate.isWeekend}"
                v-if="iconType === 'date' && iconDate"
            >
                <span>{{ iconDate.day }}</span>
                <span>{{ iconDate.labelWeekdayShort.slice(0,2) }}</span>
            </div>
        </div>
        <ion-label>
            <h3 v-if="$slots.title || title">
                <slot name="title">{{ title }}</slot>
            </h3>
            <p v-if="$slots.default || text">
                <slot>{{ text }}</slot>
            </p>
        </ion-label>
        <div class="suffix" v-if="$slots.end || props.image">
            <slot name="end">
                <img :src="props.image" class="append-image" v-if="props.image" />
            </slot>
        </div>
        <Checkbox v-if="selectable" v-model="model" slot="end" @click.stop="onClickCheckbox" />
    </ion-item>
</template>

<style lang="scss" scoped>
ion-item {
    &::part(native) {
        background-color: var(--ion-color-light);
        border-radius: 1rem;
        min-height: 4.4em;
    }

    ion-label {
        margin-top: 0.8em;
        margin-bottom: 1em;
    }

    & + ion-item {
        margin-top: 0.5em;
    }
}

.rich-response-list-item-icon {
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;

    > .icon-icon {
        width: 100%;
        font-size: var(--custom-font-size-large);
        color: var(--icon-text-color);
    }

    > .icon-text {
        font-size: var(--custom-font-size-superlarge);
        font-weight: var(--custom-font-weight-extrabold);
        color: var(--icon-text-color);
    }

    > .icon-image {
        $SIZE: 100%;
        width: $SIZE;
        height: $SIZE;
        border-radius: 50%;
        object-fit: cover;
    }

    > .icon-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > span:first-child {
            font-size: var(--custom-font-size-regular);
            font-weight: var(--custom-font-weight-bold);
            color: var(--icon-text-color);
        }

        > span:last-child {
            font-size: var(--custom-font-size-small);
            font-weight: var(--custom-font-weigh-regular);
            color: var(--ion-color-grey);
        }
    }

    &.blurred {
        > * {
            filter: blur(0.1rem);
            opacity: 0.4;
        }
    }
}

h3, p {
    font-size: var(--custom-font-weight-regular);
}

h3 {
    font-weight: var(--custom-font-weight-medium);
}

p {
    color: var(--ion-color-grey);
    display: flex;
    align-items: flex-start;
}

.suffix {
    &:not(:last-child) {
        margin-right: 1em;
    }

    > img {
        width: 2em;
        height: 2em;
        overflow: hidden;
        border-radius: 50%;
        object-fit: cover;
    }
}
</style>

import {AInesAssistantFeatureMapping, AInesAssistantType, AInesFeatureSet} from "@/graphql/generated/graphql";
import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {getArrayUnique} from "@/helper/array";

let mappingPromise : Promise<AInesAssistantFeatureMapping[]> | undefined;

export function warmUpAssistantFeatureMapping() {
    if (!mappingPromise) {
        mappingPromise = fetchAInesAssistantFeatureMapping();
    }
    return mappingPromise;
}
export function reheatAssistantFeatureMapping() {
    mappingPromise = fetchAInesAssistantFeatureMapping();
    return mappingPromise;
}
export function resetAssistantFeatureMapping() {
    mappingPromise = undefined;
}

async function fetchAInesAssistantFeatureMapping(): Promise<AInesAssistantFeatureMapping[]> {
    const result = await query(
        gql`query GetAssistantTypesMapping {
            assistantTypesMapping {
                feature
                assistant
            }
        }`
    )

    return result.data.assistantTypesMapping
}

export async function getAvailableAssistants() : Promise<AInesAssistantType[]> {
    const mapping = await (mappingPromise || warmUpAssistantFeatureMapping())
    return getArrayUnique(mapping.map(it => it.assistant))
}

export async function getAssistentForFeatureSet( featureSet: AInesFeatureSet) : Promise<AInesAssistantType | undefined> {
    const mapping = await (mappingPromise || warmUpAssistantFeatureMapping())
    const assistantString = mapping.find((it) => it.feature === featureSet)?.assistant
    const assistant = (assistantString && AInesAssistantType.hasOwnProperty(assistantString)) ? assistantString as AInesAssistantType : undefined
    return assistant
}

export async function requireAssistentForFeatureSet( featureSet: AInesFeatureSet) : Promise<AInesAssistantType> {
    const assistant = await getAssistentForFeatureSet(featureSet)
    if (!assistant) {
        throw new Error('no assistant for ' + AInesFeatureSet[featureSet])
    }
    return assistant
}

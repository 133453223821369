<template>
    <RichResponseListItem
        iconType="text"
        icon="?"
        icon-blurred
        :title="initData ? 'Suche nach &quot;' + initData + '&quot;' :'Tippe hier, um deine Stationen zu verwalten.'"
        :selectable="false"
        v-on:click="onClick"
        :button="true"
    />
    <JobsiteSelectorModal ref='modal' v-model="modalValue" :search-string="initData" v-on:select="onSelect"/>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {computed, ref, Ref} from "vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import JobsiteSelectorModal from "@/views/components/JobsiteSelectorModal.vue";
import {sendUserMessage} from "@/helper/chat/chatBL";
import {Jobsite} from "@/graphql/generated/graphql";

const props = defineProps<{
    initData: string
}>()

const modal = ref()
const modalValue: Ref<Boolean> = ref(false)
const onClick = () => {
    modalValue.value = true
    modal.value.searchByNewQuery(props.initData)
}

const onSelect = (jobsite: Jobsite) => {

    const jobsiteFragment: any = {
        "id": jobsite.id,
        "name": jobsite.name,
        "abbreviation": jobsite.abbreviation,
        "additionalAmenImportantAbbreviations": jobsite.additionalAmenImportantAbbreviations,
        "additionalAmenImportantLabels": jobsite.additionalAmenImportantLabels,
        "isFavorite": false,
        "isAlternative": true,
        "isBooked": true,
        "client": {
            "name": "Vivantes - Netzwerk für Gesundheit GmbH",
            "abbreviation": "Vivantes",
        },
    }

    if(jobsite.house){
        jobsiteFragment.house = {
            "name": jobsite.house.name,
            "abbreviation": jobsite.house.abbreviation
        }
    }

    sendUserMessage("Durch die Suchfunktion in der App habe ich eine relevante Station gefunden. Damit du sie jetzt auch kennst, sind hier die relevanten Informationene: \n" + JSON.stringify(jobsiteFragment), {isScriptedContent: true})
}


</script>
